import { handleActions } from 'redux-actions';
import {
  receiveEquipment,
  receiveNotifications,
  setEquipmentStatuses,
  addEquipmentStatus,
  dismissNotification,
  dismissAllNotifications,
} from './equipmentActions';

export const defaultState = {
  equipment: [],
  notifications: [],
  equipmentStatuses: [],
};

export default handleActions(
  {
    [receiveEquipment]: (state, action) => ({
      ...state,
      equipment: action.payload,
    }),
    [receiveNotifications]: (state, action) => {
      return {
        ...state,
        notifications: action.payload,
      };
    },
    [setEquipmentStatuses]: (state, action) => ({
      ...state,
      equipmentStatuses: action.payload,
    }),
    [addEquipmentStatus]: (state, action) => {
      const currentStatus = state.equipmentStatuses.find(
        (eq) => eq.equipment_id === action.payload.equipmentId
      );

      let statusesActual;

      if (currentStatus) {
        statusesActual = [...state.equipmentStatuses].map((eq) =>
          eq.equipment_id === action.payload.equipmentId
            ? { ...eq, status: action.payload.status }
            : eq
        );
      } else {
        statusesActual = [
          ...state.equipmentStatuses,
          {
            equipment_id: action.payload.equipmentId,
            status: action.payload.status,
            changed_date: action.payload.occurredOn,
          },
        ];
      }

      return {
        ...state,
        equipmentStatuses: statusesActual,
      };
    },
    [dismissNotification]: (state, action) => ({
      ...state,
      notifications: state.notifications.filter(
        (notification) => action.payload.occurredOn !== notification.occurredOn
      ),
    }),
    [dismissAllNotifications]: (state) => ({
      ...state,
      notifications: [],
    }),
  },
  defaultState
);
