import { handleActions } from 'redux-actions';
import { receiveRankingHistory } from './rankingHistoryActions';

const defaultState = {
  rankHistory: [],
};

export default handleActions(
  {
    [receiveRankingHistory]: (state, action) => {
      return {
        ...state,
        rankHistory: action.payload.rankHistory,
      };
    },
  },
  defaultState
);
