import React from 'react';
import styled from '@emotion/styled/macro';
import { Icon } from '../common/Icon';
import './Toast.css';

const ToastContainer = styled.div`
  font-size: 1rem;
  padding: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 400px;
  margin: auto;
  z-index: 50;
  border-left: 5px solid var(--color-green);
  background-color: var(--color-green);
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 0.5rem;
  align-items: center;
`;

const ToastText = styled.span`
  font-weight: bold;
  color: var(--color-dark);
  text-transform: uppercase;
`;

const SimpleNotification = ({ text }) => {
  return (
    <div className="toast-list__simple-notification">
      <ToastContainer>
        <Icon name={`cra-icon-rewind`} className={`rewind-icon`} />
        <ToastText>{text}</ToastText>
      </ToastContainer>
    </div>
  );
};

export default SimpleNotification;
