import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Toast.css';

const spring = {
  type: 'spring',
  damping: 20,
  stiffness: 200,
};

const motionChild = {
  visible: (custom) => ({
    opacity: 1,
    x: 0,
    transition: { delay: (custom + 1) * 0.1 },
  }),
  hidden: (custom) => ({
    opacity: 0,
    x: -50,
    transition: { delay: custom * 0.01 },
  }),
  exit: (custom) => ({
    opacity: 0,
    x: 50,
    transition: { delay: custom * 0.01 },
  }),
};

export const ToastList = ({ children, notifications }) => {
  return (
    <div className="">
      <AnimatePresence>
        {Object.keys(notifications).map((item, index) => (
          <motion.div
            key={index}
            custom={index}
            initial="hidden"
            animate={'visible'}
            exit="exit"
            positionTransition={spring}
            variants={motionChild}
            className="toast-list__item"
          >
            {children(item, notifications[item])}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastList;
