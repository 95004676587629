import React, { useState } from 'react';
import useTimeline from '../../hooks/useTimeline';

import List from './AbnormalsList';
import TimeRangePicker from './AbnormalsTimeRangePicker';

import './Abnormals.css';
import { DateTime } from 'luxon';

const Events = () => {
  const [toDate, setToDate] = useState(DateTime.local());
  const [fromDate, setFromDate] = useState(toDate.minus({ hours: 2 }));
  const { abnormals, loading } = useTimeline(fromDate, toDate);

  function handleFromDateChange(date) {
    if (date <= DateTime.local() && date <= toDate) {
      setFromDate(date);
    }
  }

  function handleToDateChange(date) {
    if (date <= DateTime.local() && date >= fromDate) {
      setToDate(date);
    }
  }

  return (
    <div className="abnormals">
      <div className="abnormals-timerange">
        <div className="abnormals-timerange__main">
          <TimeRangePicker
            label="from"
            date={fromDate}
            handleChange={handleFromDateChange}
            max={toDate}
          />
          <TimeRangePicker
            label="to"
            date={toDate}
            handleChange={handleToDateChange}
            min={fromDate}
            max={DateTime.local()}
          />
        </div>
      </div>
      <List abnormals={abnormals} loading={loading} />
    </div>
  );
};
export default Events;
