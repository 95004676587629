import React, { memo, useRef } from 'react';
import AnimateList from '../../components/common/AnimateList';
import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';

const MotionListContainer = motion(styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 2;
  overflow-anchor: none;
  margin: 0 1rem 2rem 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`);

const AnimatedList = styled(AnimateList)`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  padding: 0 8px;
  background-color: var(--color-dark-shade);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: ${(props) => (props.withChildren ? '0' : '1rem')};
`;

const ConsequencesList = (props) => {
  const { listName, children } = props;
  const listRef = useRef(null);

  return (
    <MotionListContainer ref={listRef} layout="position">
      <AnimatedList className={`list-${listName}s`} withChildren={children[0].length === 0}>
        {children}
      </AnimatedList>
    </MotionListContainer>
  );
};

export default memo(ConsequencesList);
