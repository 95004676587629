import React, { Children } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const spring = {
  type: 'spring',
  damping: 30,
  stiffness: 300,
  velocity: 5,
};

const motionChild = {
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    x: 0,
    transition: { ...spring, delay: (custom + 0.5) * 0.03 },
  }),
  hidden: (custom) => ({
    opacity: 0,
    x: 400,
    transition: { ...spring, delay: custom * 0.1 },
  }),
  exit: (custom) => ({
    opacity: 0,
    y: 0,
    x: 400,
    transition: { ...spring, delay: custom * 0.01 },
  }),
};

export const AnimateList = ({
  children,
  className,
  isVisible,
  animationComplete,
  animationStarted,
}) => {
  let elements = Children.toArray(children);

  return (
    <AnimatePresence>
      {isVisible && isVisible === true && (
        <motion.div
          className={className || 'animate-list'}
          initial="hidden"
          animate="visible"
          exit="exit"
          positionTransition={spring}
          variants={motionChild}
          onAnimationComplete={animationComplete}
          onAnimationStart={animationStarted}
        >
          {elements.map((item, index, { length }) => (
            <div
              key={item.key}
              data-key={item.name}
              custom={index}
              length={length}
              className="animate-list__item"
            >
              {item}
            </div>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
