import { createSelector } from 'reselect';

export const getRankingHistory = (state) => state.rankingHistory.rankHistory;

export const getRankHistory = createSelector(getRankingHistory, (rank) => {
  return rank.map((i) => {
    if (i && i.relativeRank && i !== null) {
      const neutralSpace = (1 - (i.relativeRank.positive + i.relativeRank.negative)).toFixed(2);
      return {
        timestamp: i.timestamp,
        positive: i.relativeRank.positive,
        neutral: neutralSpace,
        negative: i.relativeRank.negative,
      };
    } else {
      return {
        timestamp: i.timestamp,
        positive: 0,
        neutral: 0,
        negative: 0,
      };
    }
  });
});

export const getRankValue = createSelector(getRankingHistory, (rank) => {
  return rank.map((i) => {
    if (i !== null) {
      const rank = i.relativeRank.positive - i.relativeRank.negative;
      return {
        timestamp: i.timestamp,
        rank: rank,
      };
    } else {
      return {
        timestamp: 0,
        rank: 0,
      };
    }
  });
});
