import React from 'react';
import { DateTime } from 'luxon';

const formatDate = (date) => {
  if (!date) {
    return;
  }
  return date.toFormat('yyyy-MM-dd');
};

const formatTime = (date) => {
  if (!date) {
    return;
  }
  return date.toFormat('HH:mm');
};

export default function AbnormalsTimeRangePicker({ date, min, max, handleChange, label }) {
  function parseValue(valueAsDate) {
    if (valueAsDate) {
      return DateTime.fromJSDate(valueAsDate).toUTC();
    }
  }

  function handleTime(dateObject) {
    if (dateObject) {
      const newDate = date.set({ hour: dateObject.hour, minute: dateObject.minute });
      handleChange(newDate);
    }
  }

  function handleDate(dateObject) {
    if (dateObject !== undefined) {
      const newDate = date.set({
        year: dateObject.year,
        month: dateObject.month,
        day: dateObject.day,
      });
      handleChange(newDate);
    }
  }

  return (
    <div className="abnormals-timerange__date-inputs">
      <span>{label.toUpperCase()}</span>
      <div className="abnormals-timerange__date-input">
        <input
          id="from-date"
          className="input__without-buttons"
          type="date"
          value={formatDate(date)}
          onChange={({ target: { valueAsDate } }) => handleDate(parseValue(valueAsDate))}
          max={formatDate(max)}
          min={formatDate(min)}
          required
        />
        <input
          id="from-time"
          className="input__without-buttons"
          type="time"
          value={formatTime(date)}
          onChange={({ target: { valueAsDate } }) => handleTime(parseValue(valueAsDate))}
          required
        />
      </div>
    </div>
  );
}
