import React from 'react';
import { motion } from 'framer-motion';
import { Text } from '@visx/text';

const Ticks = ({ x, id, text, color = '#E4E7EB', width = '1', opacity = 0.2 }) => {
  return (
    <motion.g key={id} transition={{ type: 'tween' }} initial={{ x }} animate={{ x }} exit={{ x }}>
      <line
        strokeOpacity={opacity}
        strokeWidth={width}
        stroke={color}
        x1={0}
        y1={0}
        x2={0}
        y2="100%"
        transform="translate(0 -20)"
        shapeRendering="optimizeSpeed"
      />

      <Text
        x={0}
        y="100%"
        transform="translate(0 -10)"
        verticalAnchor="start"
        textAnchor="middle"
        fill={color}
        fontSize={12}
        fontFamily="inherit"
        fillOpacity={opacity}
      >
        {text}
      </Text>
    </motion.g>
  );
};

export default Ticks;
