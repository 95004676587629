import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as IconTopBarLogo } from '../../../svgs/topbar-logo.svg';
import './StateCircles.css';
import { VerifiedCheckmark } from '../../../components/svgIcons/cra-verified-checkmark';
import { GreenCheckMark } from '../../../components/svgIcons/cra-green-checkmark';

const logo = {
  idle: {
    opacity: 1,
  },
};

const circles = {
  idle: (i) => ({
    scale: [1.1 * i, 0.8 * i],
    fillOpacity: [0.1 * i, 0.1],
    strokeOpacity: 0.5,
    transition: {
      repeatType: 'reverse',
      repeat: Infinity,
      ease: [0.65, -0.06, 0.52, 0.96],
      duration: 2,
    },
  }),
  empty: () => ({
    scale: [null, 0.6],
    strokeOpacity: 0,
    fillOpacity: 0.1,
    transition: {
      duration: 0.5,
    },
  }),
  selected: () => ({
    scale: [null, 0.9],
    strokeOpacity: 0.1,
    fillOpacity: 0.2,
    transition: {
      ease: 'circOut',
      duration: 1,
    },
  }),
};

export const StateCircles = ({ isGreen = false, logoImg = null }) => {
  // 'empty' | 'idle' | 'selected'
  const mode = 'idle';

  const CONSTANTS = {
    GREEN: isGreen ? '#50e3c2' : '#373946',
    GRAY: isGreen ? '#373946' : '#2d2f3c',
  };

  const container = {
    initial: {
      color: CONSTANTS.GRAY,
      transition: {
        duration: 2,
        ease: [0.65, -0.06, 0.52, 0.96],
      },
    },
    idle: {
      color: [null, CONSTANTS.GREEN],
      transition: {
        duration: 2,
        ease: [0.65, -0.06, 0.52, 0.96],
      },
    },
    empty: {
      color: CONSTANTS.GRAY,
      transition: {},
    },
    selected: {
      color: CONSTANTS.GRAY,
      transition: {},
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        key="gray-small"
        animate={mode}
        variants={container}
        exit="initial"
        className={isGreen ? 'state-circle' : 'state-circles-no-z'}
        initial={'initial'}
      >
        <motion.div className="circle__logo" variants={logo}>
          {isGreen ? (
            logoImg ? (
              <img src={`data:image/svg+xml;base64,${logoImg}`} style={{ width: 50, height: 50 }} />
            ) : (
              <IconTopBarLogo />
            )
          ) : (
            <GreenCheckMark />
          )}
        </motion.div>
        <svg
          className="circle__pulsar circle--pulse"
          viewBox="0 0 10% 10%"
          width="400"
          height="400"
          preserveAspectRatio="xMidYMid slice"
        >
          <motion.circle
            cx="50%"
            cy="50%"
            r="50%"
            animate={mode}
            variants={circles}
            fillOpacity={0}
            strokeOpacity={0.1}
            custom={0.85}
            className="circle__shade"
          />
          <motion.circle
            cx="50%"
            cy="50%"
            r="40%"
            animate={mode}
            variants={circles}
            custom={0.9}
            fillOpacity={0}
            strokeOpacity={0.1}
            className="circle__shade"
          />
          <motion.circle
            cx="50%"
            cy="50%"
            r="30%"
            animate={mode}
            variants={circles}
            custom={1}
            fillOpacity={0}
            strokeOpacity={0.1}
            className="circle__shade"
          />
        </svg>
      </motion.div>
    </AnimatePresence>
  );
};
