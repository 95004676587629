import React from 'react';
import CardButton from '../cards/CardButton';
import { motion } from 'framer-motion';

const ListButtons = ({ text, visible = true, onShowMoreClick, onResetClick }) => {
  return (
    visible && (
      <motion.div layout="position" key="button-list">
        <div className={`buttons-list`}>
          <CardButton
            type="plus"
            title={text}
            onCardClick={onShowMoreClick}
            // disabled={list.length <= visiblesCount}
          />
          <CardButton type="reset" title="Reset list" onCardClick={onResetClick} disabled={false} />
        </div>
      </motion.div>
    )
  );
};

export default ListButtons;
