import { createSelector } from 'reselect';
import { LIVE_MODE, REWIND_MODE, INITIAL_MODE } from './types';
export const getMode = (state) => state.modes.mode;

export const modeIsLive = createSelector(getMode, (mode) => {
  return mode === LIVE_MODE;
});

export const modeIsRewind = createSelector(getMode, (mode) => {
  return mode === REWIND_MODE;
});

export const modeIsInitial = createSelector(getMode, (mode) => {
  return mode === INITIAL_MODE;
});
