import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Equipment.css';

const spring = {
  type: 'spring',
  damping: 20,
  stiffness: 200,
};

const motionChild = {
  visible: (custom) => ({
    opacity: 1,
    x: 0,
    transition: { delay: (custom + 1) * 0.1 },
  }),
  hidden: (custom) => ({
    opacity: 0,
    x: -50,
    transition: { delay: custom * 0.01 },
  }),
  exit: (custom) => ({
    opacity: 0,
    x: 50,
    transition: { delay: custom * 0.01 },
  }),
};

export const EquipmentNotifications = ({ children, list, isVisible }) => {
  return (
    <div className="notification-list">
      <AnimatePresence>
        {list.map((item, index) => (
          <motion.div
            key={item.equipmentId + item.occurredOn}
            custom={index}
            initial="hidden"
            animate={isVisible && 'visible'}
            exit="exit"
            positionTransition={spring}
            variants={motionChild}
            className="notification-list__item"
          >
            {children(item)}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

const dismissAfterDelay = (dissmissFunc, equipment) => {
  //dismiss delay
  const delay = 300000;
  setTimeout(() => {
    dissmissFunc(equipment);
  }, delay);
};

export const EquipmentNotification = (props) => {
  const {
    equipment: { equipmentName: name, equipmentTag: description },
    operational,
    onNotificationClick,
  } = props;
  const statusLabel = operational ? 'In Operation' : 'Out of Operation';

  //dismisses notification after delay specified above
  dismissAfterDelay(onNotificationClick, props.equipment);

  return (
    <div
      className="notification"
      data-operational={operational}
      onClick={() => onNotificationClick(props.equipment)}
    >
      {/* <svg viewBox="0 0 18 6" width="18" height="6" className="notification-pointer">
        <polygon points="9,0 0,6 18,6" fill="var(--background-color)" />
      </svg> */}
      <div className="notification-icon" />
      <span className="text-bold">{name}</span>
      <span className="color-gray">{description}</span>
      <span>{statusLabel}</span>
    </div>
  );
};

// export default EquipmentNotification;
