import { handleActions } from 'redux-actions';
import { modeChange } from './actions';
import { INITIAL_MODE, LIVE_MODE, REWIND_MODE, TOGGLE, COMPLETED } from './types';

const initialModeState = {
  mode: INITIAL_MODE,
};

const machine = {
  [INITIAL_MODE]: {
    [COMPLETED]: LIVE_MODE,
  },
  [LIVE_MODE]: {
    [TOGGLE]: REWIND_MODE,
  },
  [REWIND_MODE]: {
    [TOGGLE]: LIVE_MODE,
  },
};

const transition = (state, event) => {
  const nextState = machine[state][event];
  return nextState !== undefined ? nextState : state;
};

export default handleActions(
  {
    [modeChange]: (state, { payload }) => {
      const newMode = transition(state.mode, payload);
      return {
        ...state,
        mode: newMode,
      };
    },
  },
  initialModeState
);
