import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';

export const ListContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 2;
  overflow-anchor: none;
  position: relative;
`;

export const MotionListContainer = motion(ListContainer);
