import React from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';

const defaultMargin = { top: 5, right: 5, bottom: 5, left: 5 };

const RankingIndicator = ({ rank, width, height, margin = defaultMargin, thickness }) => {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = thickness ? thickness : 5;

  // starting value and maximum value to calculate the pie percentage from.
  // min and max values are calculated from the circle length, so 2*Math.Pi is full circle length
  const minValue = 0;
  const maxValue = 2 * Math.PI;

  const labels = Object.keys(rank);
  const ranking = Object.keys(rank).map((item) => ({
    label: item,
    number: Math.abs(rank[item]).toFixed(2),
  }));

  const neutralSpace = (1 - (rank.positive + Math.abs(rank.negative))).toFixed(2);

  if (neutralSpace > 0) {
    ranking.splice(1, 0, {
      label: 'neutral',
      number: neutralSpace,
    });
  }

  //accessor function is used by visix to derive numeric values for each of the pies to be displayed
  const accessorFunction = (item) => item.number;

  // color scales
  const rankingColor = scaleOrdinal({
    domain: labels,
    range: [
      'var(--color-observations)', // yellow for positive
      'var(--color-gray)', // light gray for negative
      'var(--color-darkest)', // dark gray for neutral
    ],
  });

  return (
    <svg width={width} height={height}>
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={ranking}
          pieValue={accessorFunction}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={0}
          padAngle={0}
          startAngle={minValue}
          endAngle={maxValue}
          pieSort={null}
          pieSortValues={null}
        >
          {(pie) => {
            return (
              <Group className="visx-pie-arcs-group">
                {pie.arcs.map((arc, i) => (
                  <g key={`pie-arc-${i}`}>
                    <path d={pie.path(arc) || ''} fill={rankingColor(arc.data.label)} />
                  </g>
                ))}
              </Group>
            );
          }}
        </Pie>
      </Group>
    </svg>
  );
};

export default RankingIndicator;
