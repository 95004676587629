import React, { useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';

import rootReducer from '../store/rootReducer';

let middleware = [];
let enhancers = [];

export const sagaMiddleware = createSagaMiddleware();

middleware = [...middleware, sagaMiddleware];

enhancers = [...enhancers, applyMiddleware(...middleware)];

const storeInstance = createStore(rootReducer, composeWithDevTools(...enhancers));

export const store = storeInstance;

const Store = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default Store;
