import styled from '@emotion/styled/macro';

export const Heading = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0 var(--spacing-30);
`;
