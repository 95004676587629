import { handleActions } from 'redux-actions';

import { receiveSensors } from './sensorsActions';

const defaultState = {
  sensors: [],
};

export default handleActions(
  {
    [receiveSensors]: (state, action) => ({
      ...state,
      sensors: action.payload,
    }),
  },
  defaultState
);
