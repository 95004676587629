import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

const Cell = ({ val }) => {
  return val ? (
    <div className="abnormals-card__table-cell">
      <span>{DateTime.fromISO(val).toFormat('dd.LL HH:mm')}</span>
    </div>
  ) : (
    <div />
  );
};

const getDuration = (start, end) => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  const diff = endDate
    .diff(startDate, ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'])
    .toObject();
  let diffOutput = '';
  for (const property in diff) {
    if (diff[property] > 0) {
      if (diffOutput === '') {
        diffOutput += `~ ${parseInt(diff[property])} ${property}`;
      }
    }
  }
  return diffOutput;
};
const checkIfOnGoing = (date) => {
  const now = new Date().setSeconds(-10);
  const lasted = new Date(date);
  return lasted > now ? null : date;
};
const TableRow = ({ abnormalEntry, index, isLast }) => {
  return (
    <div className="abnormals-card__row">
      <span className="state-label">{abnormalEntry.fault}</span>
      <Cell val={abnormalEntry.started} isStart={true} index={index} />
      <Cell
        val={isLast ? checkIfOnGoing(abnormalEntry.lasted) : abnormalEntry.lasted}
        isStart={false}
        index={index}
      />
      <span>
        {abnormalEntry.lasted
          ? getDuration(abnormalEntry.started, abnormalEntry.lasted)
          : getDuration(abnormalEntry.started, DateTime.local())}
      </span>
    </div>
  );
};

const AbnormalCard = ({ abnormal }) => {
  const [expanded, setExpanded] = useState(false);
  const [, setCurrentState] = useState('');

  const getCurrentState = () => {
    if (abnormal.observations && abnormal.observations.length > 0) {
      const lastTimelineValues = abnormal.observations[0];
      const normalState = lastTimelineValues && lastTimelineValues.end !== undefined;
      const lastSensorValueState = lastTimelineValues.fault;
      if (!normalState) {
        return lastSensorValueState.toUpperCase();
      }
    }
    return 'NORMAL';
  };

  useEffect(() => {
    setCurrentState(getCurrentState());
  }, []);
  return (
    <div className="abnormals-card">
      <div className="abnormals-card__head">
        <div className="abnormals-card__name-container">
          <span>{abnormal.label}</span>
          <span>{abnormal.sensor}</span>
        </div>
      </div>

      <div className={`abnormals-card__table ${expanded ? 'abnormals-card__table-expanded' : ''}`}>
        <div className="abnormals-card__table-head">
          <div />
          <span>Started</span>
          <span>Ended</span>
          <span>Duration</span>
        </div>
        {abnormal.observations.map((abnormalEntry, i, { length }) => {
          return (
            <TableRow
              abnormalEntry={abnormalEntry}
              index={i}
              key={`${abnormalEntry.id}-${abnormalEntry.started}-${abnormalEntry.lasted}`}
              isLast={i + 1 === length}
            />
          );
        })}
        {abnormal.observations.length > 3 && !expanded ? (
          <>
            <span />
            <span />
            <span />
            <span className="abnormals-card__table-counter">
              3<span>/</span>
              <span>{abnormal.observations.length}</span>
            </span>
          </>
        ) : null}
      </div>
      <div>
        {abnormal.observations.length > 3 ? (
          <button className="abnormals-card__expand-btn" onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Collapse' : 'Expand'}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default AbnormalCard;
