import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import client from '../../store/connect';
import { fetchSensorData } from '../../store/api';
import { lowBound, highBound } from '../timeline/utils/bounds';
import { useSelector } from 'react-redux';
import { getRewindTimestamp, getTimestamp } from '../../store/ui/uiSelectors';
import { modeIsLive, modeIsRewind } from '../../store/mode/selectors';
import { createSelector } from 'reselect';

const normalizeData = ({ sensor_value, event_time }) => {
  return {
    value: sensor_value,
    date: new Date(event_time).valueOf(),
  };
};

const initialSensorState = {
  data: [],
  minValue: Number.POSITIVE_INFINITY,
  maxValue: Number.NEGATIVE_INFINITY,
};

function data(sensorData) {
  return (prevState) => {
    // get highest and lowest value from new data
    // Using Infinity to make sure any number is higher/lower
    const minOfData = sensorData.length
      ? Math.min(...sensorData.map((i) => i.value))
      : Number.POSITIVE_INFINITY;
    const maxOfData = sensorData.length
      ? Math.max(...sensorData.map((i) => i.value))
      : Number.NEGATIVE_INFINITY;
    const { minValue, maxValue } = prevState;

    return {
      data: [...prevState.data, ...sensorData],
      maxValue: Math.max(maxOfData, maxValue),
      minValue: Math.min(minOfData, minValue),
    };
  };
}
const getSensorDataTimestamp = createSelector(
  getTimestamp,
  getRewindTimestamp,
  modeIsRewind,
  (timelineTimestamp, rewindTimestamp, isRewind) => {
    if (isRewind) {
      return rewindTimestamp;
    }
    return timelineTimestamp;
  }
);

export const useSensorData = (sensorName, limits) => {
  const [sensorData, setSensorData] = useState(() => initialSensorState);
  const [mode, setMode] = useState('live');
  const [lastValue, setLastValue] = useState(null);
  const limitsValues = limits.map(({ limit }) => limit);
  const { TIMELINE_WINDOW } = useSelector((state) => state.api.flags);
  const timestamp = useSelector(getSensorDataTimestamp);
  const isLive = useSelector(modeIsLive);

  useEffect(() => {
    const now = DateTime.fromISO(timestamp);
    const from = now.minus(TIMELINE_WINDOW);

    // consider making this cancellable because it's within a useEffect
    fetchSensorData(sensorName, {
      to: now.toISO(),
      from: from.toISO(),
    })
      .then(({ response }) => {
        return { sensorData: response.data.map(normalizeData), mode: response.mode };
      })
      .then(({ sensorData, mode }) => {
        if (sensorData.length !== 0) {
          if (mode === 'live') {
            setSensorData(() => data(sensorData)(initialSensorState));
          } else {
            const mockedSensorData = sensorData.map((data) => {
              return {
                value: sensorData[sensorData.length - 1].value,
                date: data.date,
              };
            });
            setSensorData(() => data(mockedSensorData)(initialSensorState));
            setMode('history');
            setLastValue(sensorData[sensorData.length - 1].value);
          }
        } else {
          setMode('history');
          setLastValue(null);
        }
      });
  }, [timestamp]);

  useEffect(() => {
    // Set up the subscription of the sensor.
    client.emit('subscribe', sensorName);
    return () => {
      // ... and cancel when the component unmounts
      client.emit('unsubscribe', sensorName);
      client.off(sensorName);
    };
  }, []);

  return [
    sensorData.data,
    sensorData.minValue,
    sensorData.maxValue,
    lowBound(sensorData.minValue, limitsValues),
    highBound(sensorData.maxValue, limitsValues),
    lastValue,
    mode,
  ];
};
