import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as CloseIcon } from '../../svgs/icon-close.svg';
import { Icon } from '../common/Icon';

const ToastContainer = styled.div`
  font-size: 1rem;
  padding: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 400px;
  margin: auto;
  z-index: 10;
  border-left: 5px solid var(--color-observations);
  background-color: var(--color-observations);
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 0.5rem;
  align-items: center;
`;

const ToastText = styled.span`
  font-weight: bold;
  color: var(--color-dark);
  text-transform: uppercase;
`;

const ToastButton = styled.button`
  font-weight: bold;
  color: var(--color-dark);
  padding-right: 2rem;
  text-decoration: underline;
`;

const Toast = ({ type, text, onClose, buttonClick }) => {
  return (
    <ToastContainer>
      <Icon name={`cra-icon-rewind`} className={`rewind-icon`} />
      <ToastText>{text}</ToastText>
      <ToastButton onClick={() => buttonClick()}>{'Exit'}</ToastButton>
    </ToastContainer>
  );
};

export default Toast;
