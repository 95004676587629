function bounds(dataPoint, arr) {
  const sorted = arr.sort();
  const i = sorted.indexOf(dataPoint);
  return i + 1 !== sorted.length ? sorted.slice(i, i + 2) : sorted.slice(i - 1);
}

function determineBound(fn, dataPoint, limits) {
  if (!limits || !limits.length) return dataPoint;
  return fn(...bounds(dataPoint, [dataPoint, ...limits]));
}

export function lowBound(dataPoint, limits) {
  return determineBound(Math.min, dataPoint, limits);
}

export function highBound(dataPoint, limits) {
  return determineBound(Math.max, dataPoint, limits);
}
