import { useState, useEffect } from 'react';
import useDebounce from './useDebounce';
import { fetchTimeline, fetchModel } from '../store/api';

const getData = async (from, to) => {
  const { response: timeline } = await fetchTimeline({
    from: from.toUTC(),
    to: to.toUTC(),
  });
  const { response: model } = await fetchModel();
  const mappedTimelineToModel = timeline.map((entry) => {
    const { id, observations } = entry;
    const modelObject = model[id];

    return {
      id,
      observations,
      label: modelObject.function.label,
      sensor: modelObject.sensor,
      limits: modelObject.limits,
      unit: modelObject.unit,
    };
  });
  return mappedTimelineToModel;
};

const useTimeline = (from, to) => {
  const [abnormals, setAbnormals] = useState([]);

  const debouncedTo = useDebounce(to, 500);
  const debouncedFrom = useDebounce(from, 500);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getData(debouncedTo, debouncedFrom)
      .then(setAbnormals)
      .then(() => setLoading(false));
  }, [debouncedFrom, debouncedTo]);

  return { abnormals, loading };
};

export default useTimeline;
