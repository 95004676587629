import { put, takeLatest, spawn, select, take, call } from 'redux-saga/effects';
import { getCauses } from '../graph/graphSelectors';
import { cardClick, selectCause, consequenceClick, selectConsequence } from './uiActions';

import { receivedGraphState } from '../api/actions';

function* cardClicked({ payload }) {
  const { cause, counteractions } = payload;
  yield put(selectCause(cause));
}

function* consequenceClicked({ payload }) {
  const { uuid } = payload;
  yield put(selectConsequence(uuid));
}

function* cardDeselected() {
  yield put(selectCause(null));
}

function* watcherNormalMode() {
  while (true) {
    yield take(receivedGraphState);
    const causes = yield select(getCauses);
    if (!causes.length) {
      yield call(cardDeselected);
    }
  }
}

function* watchUiActions() {
  yield takeLatest(cardClick, cardClicked);
  yield takeLatest(consequenceClick, consequenceClicked);
}

export default function* uiSagas() {
  yield spawn(watchUiActions);
  yield spawn(watcherNormalMode);
}
