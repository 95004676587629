import React from 'react';

import { GridColumns } from '@visx/grid';
import { AxisBottom } from '@visx/axis';

const TrendGrid = ({
  height,
  width,
  scale,
  fontSize = 12,
  offsetBy = 10,
  formatFunction,
  numTicks,
  type,
}) => {
  return (
    <>
      <GridColumns
        className="trends__grid-column"
        scale={scale}
        width={width}
        height={height}
        strokeWidth="1"
        stroke="#f2f2f2"
        numTicks={numTicks}
        strokeOpacity="0.2"
      />
      <AxisBottom
        top={height}
        scale={scale}
        tickFormat={formatFunction}
        numTicks={numTicks}
        hideTicks={true}
        hideAxisLine={true}
        labelOffset={0}
        tickStroke="currentColor"
        tickLabelProps={(value) => {
          const date = new Date(value);
          const hours = date.getHours();
          const minutes = date.getMinutes();
          return {
            fill: 'currentColor',
            y: offsetBy,
            fontSize: fontSize,
            opacity: 0.6,
            fontFamily: 'inherit',
            textAnchor: 'middle',
            verticalAnchor: 'start',
            visibility: hours === 0 && minutes === 0 || type !== 'date' ? 'visible' : 'hidden',
          }
        }}
      />
    </>
  );
};

export default TrendGrid;
