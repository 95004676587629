import { createAction } from 'redux-actions';

export const receiveEquipment = createAction('Equipment/EQUIPMENT_RECEIVED');

export const receiveNotifications = createAction('Equipment/NOTIFICATIONS_RECEIVED');

export const dismissNotification = createAction('Equipment/DISMISS_NOTIFICATION');

export const dismissAllNotifications = createAction('Equipment/DISMISS_ALL_NOTIFICATIONS');

export const setEquipmentStatuses = createAction('Equipment/SET_EQUIPMENT_STATUSES');

export const addEquipmentStatus = createAction('Equipment/ADD_EQUIPMENT_STATUS');
