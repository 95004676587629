import React from 'react';
import { DateTime } from 'luxon';

import { AnimateList } from './AnimateEquipmentList';

const EquipmentList = ({ list, statuses, isVisible }) => {
  return (
    <div className="equipment-lists" data-toggle={isVisible}>
      <AnimateList list={statuses} className="equipment-list" isVisible={isVisible}>
        {statuses && statuses.length > 0 && (
          <div key="ENO">
            <h4 className="text-uppercase text-normal margin-0">Not in operation</h4>
            {/* <span className="opacity-50 text-small">No causes from this equipment will be shown</span> */}
          </div>
        )}
        {statuses && statuses.length === 0 && (
          <div key="AIO">
            <div className="equipment-status-good-icon">
              <div className="equipment-status-good-icon-inner">
                <svg
                  className="check-icon"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#FFF"
                    d="M8.36,22h-.1a2,2,0,0,1-1.55-.86L.36,12A2,2,0,0,1,3.65,9.67l4.87,7,12-14a2,2,0,1,1,3,2.6L9.88,21.3A2,2,0,0,1,8.36,22Z"
                  />
                </svg>
              </div>
            </div>
            <p className="text-normal margin-0 equipment-status-good-label">
              All equipment in normal operation
            </p>
          </div>
        )}
        {statuses.map((item) => {
          const eq = list.find((el) => el.equipmentId === item.equipmentId);
          return (
            <div className="equipment-list__item" key={item.equipmentId + item.timestamp}>
              <div className="equipment-card" data-operational={item.status === 'running'}>
                <div className="text-right flex flex-align-center flex-justify-end">
                  <span className="status-indicator" />{' '}
                  <span className="opacity-50 text-small">
                    {DateTime.fromISO(item.timestamp).toFormat('dd/MM — HH:mm')}
                  </span>
                </div>
                <div className="text-bold grid-center">
                  <span className="equipment-icon-placeholder" />
                  <div className="">
                    <div>{eq?.equipmentName}</div>
                    <div className="opacity-50 text-small">{eq?.equipmentTag}</div>
                  </div>
                </div>
                <span className="opacity-70 text-small">{eq?.description}</span>
              </div>
            </div>
          );
        })}
        {/* <div key="EIO">
          <h4 className="text-uppercase text-normal margin-0">Recenly back in operation</h4>
          <span className="opacity-50 text-small">Causes from this equipment will be shown</span>
        </div> */}
      </AnimateList>
    </div>
  );
};

export default EquipmentList;
