import React from 'react';
import Card from './AbnormalCard';

export default function AbnormalsList({ abnormals, loading }) {
  return (
    <div className="abnormals-list">
      <h2 className="abnormals-list__head">Observation history</h2>
      <div className="abnormals-list__main">
        {abnormals.map((abnormal) => (
          <Card key={abnormal.id} abnormal={abnormal} />
        ))}
        {abnormals && abnormals.length === 0 && loading ? (
          <span> Loading Data... </span>
        ) : abnormals && abnormals.length === 0 && !loading ? (
          <span> No observations in selected time interval. </span>
        ) : null}
      </div>
    </div>
  );
}
