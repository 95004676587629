import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useKeycloak } from 'keycloak-react-web';

const defaultUri = 'http://localhost:3000/';

const isLocalhost =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const isInContainer = window.location.port === '';

const redirectUri = !isLocalhost
  ? `${window.location.protocol}//${window.location.hostname}/`
  : isInContainer
  ? 'http://localhost/'
  : defaultUri;

const loginOptions = {
  redirectUri: redirectUri,
};

export const ProtectedRoute = (props) => {
  const { element: Element } = props;
  //const { ENABLE_AUTH } = useSelector((state) => state.api.flags);
  const { keycloak, initialized } = useKeycloak();

  if (initialized) {
    if (!keycloak.authenticated) {
      keycloak.login(loginOptions);
    }
  }
  if (initialized && keycloak.authenticated) {
    return <Element />;
  }
};
