import React from 'react';
import { dismissNotification } from '../../store/notifications/actions';
import { getNotifications } from '../../store/notifications/selectors';
import ToastList from './ToastList';
import Toast from './Toast';
import { toggleRewind } from '../../store/mode/actions';
import SimpleNotification from './SimpleNotification';
import { useSelector, useDispatch } from 'react-redux';
import { postRewindModeMetrics } from '../../store/api';
import { setObseravationClicked } from '../../store/ui/uiActions';

const rewindText = 'Rewind active';
const updatedText = 'There have been updates to the situation since you entered Rewind.';

const Toasts = () => {
  const notifications = useSelector(getNotifications);
  const cmrsStatus = useSelector((state) => state.api.cmrsStatus);
  const sessionId = useSelector((state) => state.api.sessionId);
  const dispatch = useDispatch();

  const handleToggleRewind = async () => {
    dispatch(toggleRewind());
    dispatch(setObseravationClicked(null));
    await postRewindModeMetrics(sessionId, 'RESUME_CLICKED');
  };

  return (
    <div className="toast-list">
      {cmrsStatus === 'calculating' && (
        <SimpleNotification text="Reasoning is being performed on the model..." />
      )}
      <ToastList notifications={notifications}>
        {(type, item) =>
          item.isLive === false && (
            <Toast
              text={type === 'scrubbingActivated' ? rewindText : updatedText}
              onClose={() => dispatch(dismissNotification(type))}
              buttonClick={() => handleToggleRewind()}
              type={type}
              item={item}
            />
          )
        }
      </ToastList>
    </div>
  );
};

export default Toasts;
