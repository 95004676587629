import { put, call, all, take, fork, select, spawn } from 'redux-saga/effects';
import { subscribeToTopic } from '../connect';

import {
  fetchEquipment,
  fetchEquipmentHistory,
  fetchState,
  fetchModel,
  fetchTimelineLatest,
  fetchFlags,
  fetchEquipmentConfig,
  fetchCmrsStatus,
  fetchClientId,
  fetchSessionId,
  postStateMetrics,
} from '../api';
import {
  doBootSequence,
  receivedEquipment,
  receivedEquipmentUpdate,
  receivedGraphState,
  receivedGraphStateUpdate,
  receivedModel,
  receivedTimeline,
  receivedFlags,
  gotApiError,
  gotMostRecent,
  getMostRecent,
  receivedEquipmentConfig,
  receivedCmrsStatusUpdate,
  setCmrsStatus,
  receivedSessionId,
  receivedERStateUpdate,
} from './actions';

import { receiveNotifications } from '../equipment/equipmentActions';

import { receiveNotification } from '../notifications/actions';

import { setVerifyCauseValues } from '../verifyCause/verifyCauseActions';

import { modeIsLive, modeIsRewind } from '../mode/selectors';
import { bootUpComplete } from '../mode/actions';
import { setTick, setTimestamp } from '../ui/uiActions';
import { fetchSensorsFromApi } from '../sensors/sensorsSagas';
import {
  getCauses,
  getSessionId,
  getStateCauses,
  getStateConsequences,
} from '../graph/graphSelectors';
import { compareCausesAndConsequences } from '../../utils';
import { equipmentEvents, equipmentSelector } from '../equipment/equipmentSelectors';
import { differenceWith, isEqual } from 'lodash';
import { getFlags } from './selectors/flags';

// Boot sequence flow:
// -- get model (only done once)
// -- connect to WS (only done once)
// -- get most recent data (timeline / graph state / equipment)

// Resume live flow:
// -- get most recent data (timeline / graph state / equipment)
// -- resume listening for ws data

// TODO: We could make this DRYer. Probably better to yield the response to a task and THEN use put to fire the action.
// As such
// yield equipment = fork(fetchEquipmentFromApi);
// yield put(receivedEquipment(response))

export function* fetchEquipmentFromApi() {
  const { response, error } = yield call(fetchEquipment);
  if (response) {
    yield put(receivedEquipment(response));
  } else {
    yield put(gotApiError({ message: error, title: 'Equipment API fetch failed' }));
  }
}

export function* fetchEquipmentHistoryFromApi() {
  const { response, error } = yield call(fetchEquipmentHistory);
  if (response) {
    yield put(receivedEquipment(response));
  } else {
    yield put(gotApiError({ message: error, title: 'Equipment API fetch failed' }));
  }
}

export function* fetchSessionInfo() {
  const clientId = localStorage.getItem('clientId');
  if (!clientId) {
    const { response, error } = yield call(fetchClientId);
    localStorage.setItem('clientId', response.clientId);
  }
  const { response: sessionIdResponse, error: sessionIdError } = yield call(fetchSessionId);
  yield put(receivedSessionId(sessionIdResponse.sessionId));
}

export function* fetchFlagsFromApi() {
  const { response, error } = yield call(fetchFlags);

  if (response) {
    yield put(receivedFlags(response));
  }

  if (error) console.log(error);
}

export function* fetchGraphStateFromApi() {
  const { response, error } = yield call(fetchState);
  if (response) {
    yield put(receivedGraphState(response));
  } else {
    yield put(gotApiError({ message: error, title: 'Graph State API fetch failed' }));
  }
}

export function* fetchModelFromApi() {
  const { response, error } = yield call(fetchModel);
  if (response) {
    yield put(receivedModel(response));
  } else {
    yield put(gotApiError({ message: error, title: 'Model API fetch failed' }));
  }
}

export function* fetchEquipmentConfigFromApi() {
  const { response, error } = yield call(fetchEquipmentConfig);

  if (response) {
    yield put(receivedEquipmentConfig(response));
  } else {
    yield put(gotApiError({ message: error, title: 'Model API fetch failed' }));
  }
}

export function* fetchTimelineFromApi() {
  const { response, error } = yield call(fetchTimelineLatest);
  if (response) {
    yield put(receivedTimeline(response));
    yield put(setVerifyCauseValues(response));
  } else {
    yield put(gotApiError({ message: error, title: 'Timeline API failed' }));
  }
}

function* watchTopicUpdates(topic, action) {
  const channel = yield call(subscribeToTopic, topic);
  while (true) {
    const data = yield take(channel);
    yield put(action(data));
  }
}

export function* getLiveDataFromAPIs() {
  yield all([
    fork(fetchTimelineFromApi),
    fork(fetchGraphStateFromApi),
    fork(fetchEquipmentFromApi),
  ]);
}

function* connectToAPIWebSockets() {
  yield fork(watchTopicUpdates, 'atui-state', receivedGraphStateUpdate);
  yield fork(watchTopicUpdates, 'equipment-updated', receivedEquipmentUpdate);
  yield fork(watchTopicUpdates, 'status-cmrs', receivedCmrsStatusUpdate);
  yield fork(watchTopicUpdates, 'emissions-state', receivedERStateUpdate);
}

function* watcherGetMostRecent() {
  while (true) {
    yield take(getMostRecent);
    yield call(getLiveDataFromAPIs);
    yield put(gotMostRecent());
  }
}

function* watcherGotEquipmentUpdate() {
  while (true) {
    yield take(receivedEquipmentUpdate);
    const isLive = yield select(modeIsLive);

    if (isLive) {
      const { response: equipmentResponse, error: equipmentError } = yield call(fetchEquipment);
      const currentEquipmentEvents = yield select(equipmentEvents);
      const equipmentStatusDifference = differenceWith(
        equipmentResponse,
        currentEquipmentEvents,
        isEqual
      );
      yield put(receiveNotifications(equipmentStatusDifference));
      yield put(receivedEquipment(equipmentResponse));
    }
  }
}

function* watcherGotCmrsStatusUpdate() {
  while (true) {
    yield take(receivedCmrsStatusUpdate);
    const isLive = yield select(modeIsLive);

    if (isLive) {
      const { response: cmrsStatusResponse, error: cmrsStatusError } = yield call(fetchCmrsStatus);
      const { response, error } = yield call(fetchModel);
      yield put(receivedModel(response));
      yield put(setCmrsStatus(cmrsStatusResponse));
    }
  }
}

function* watcherGotStateUpdate() {
  while (true) {
    yield take(receivedGraphStateUpdate);
    const isRewind = yield select(modeIsRewind);
    const sessionId = yield select(getSessionId);

    if (isRewind) {
      // yield put(receiveNotification({ type: 'statusChange', isLive: false }));
    } else {
      const { response, error } = yield call(fetchState);
      const causes = yield select(getStateCauses);
      const consequences = yield select(getStateConsequences);
      const compareResult = compareCausesAndConsequences(
        causes,
        response.causes,
        consequences,
        response.consequences
      );
      if (
        response.causes.length === 0 &&
        response.consequences.length === 0 &&
        compareResult === false
      ) {
        yield call(postStateMetrics, sessionId, 'EMPTY_STATE', null);
      }
      if (compareResult === false) {
        yield call(postStateMetrics, sessionId, 'STATE_CHANGE', {
          stateCauses: causes,
          stateConsequences: consequences,
          newStateCauses: response.causes,
          newStateConsequences: response.consequences,
        });
      }
      // const { response: modelResponse, error: modelError } = yield call(fetchModel);
      // yield put(receivedModel(modelResponse));
      yield put(receivedGraphState(response));
      // yield put(receiveModel(modelResponse));
      yield put(setTimestamp(new Date()));
      yield put(setTick());
    }
  }
}

function* prepareBootUpSequence() {
  yield take(doBootSequence);
  yield call(fetchFlagsFromApi);

  const { ENABLE_STATS } = yield select(getFlags);
  if (ENABLE_STATS) {
    yield call(fetchSessionInfo);
  }
  yield call(fetchSensorsFromApi);
  yield call(fetchModelFromApi);
  yield call(fetchEquipmentConfigFromApi);
  yield call(getLiveDataFromAPIs);
  yield spawn(connectToAPIWebSockets);
  yield put(bootUpComplete());
}

export default function* apiSagas() {
  yield spawn(prepareBootUpSequence);
  yield spawn(watcherGetMostRecent);
  yield spawn(watcherGotEquipmentUpdate);
  yield spawn(watcherGotStateUpdate);
  yield spawn(watcherGotCmrsStatusUpdate);
}

export function* authSaga() {
  yield call(fetchFlagsFromApi);
}
