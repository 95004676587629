import styled from '@emotion/styled/macro';
import { Line, LinePath, Polygon } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import React from 'react';
import { curveBasis } from 'd3-shape';

const StyledSvg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const VerifyCauseLines = (props) => {
  const { width, height, verifiable, withCounteractions } = props;

  const data = [
    [0, 113],
    [width, 113],
  ];

  const xScale = scaleLinear({
    domain: [0, width],
    range: [0, width],
  });

  const yScale = scaleLinear({
    domain: [0, height],
    range: [0, height],
  });

  return (
    <StyledSvg>
      <Polygon center={{ x: 5, y: 113 }} rotate={360} sides={3} size={10} fill="#2D2F3C" />
      <LinePath
        data={data}
        curve={curveBasis}
        stroke="#2D2F3C"
        strokeWidth={2}
        x={(d) => xScale(d[0])}
        y={(d) => yScale(d[1])}
      />
      {verifiable && withCounteractions && (
        <Line
          strokeWidth={2}
          from={{ x: width / 2, y: 113 }}
          to={{ x: width / 2, y: 113 + 50 }}
          strokeDasharray="2"
          stroke="#2D2F3C"
        />
      )}
    </StyledSvg>
  );
};
