import React from 'react';
import { motion } from 'framer-motion';
import { timeFormat } from 'd3-time-format';
import { Group } from '@visx/group';
import { Icon } from '../common/Icon';
import { localPoint } from '@visx/event';

const formatDate = timeFormat('%H:%M');
const getSensorDate = (d) => {
  if (d.date) {
    return d.date;
  }
  if (d.timestamp) {
    return new Date(d.timestamp);
  }
};
const getSensorValue = (d) => {
  let val = 0;

  if (d.value) {
    val = d.value;
  }
  if (d.rank) {
    val = d.rank;
  }

  return val === 0 ? 0 : (Math.round(val * 100) / 100).toFixed(2);
};

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 50,
};

const TrendTooltip = ({ data, left, top, unit = '' }) => {
  return (
    <motion.div
      className="trend__tooltip"
      //data-hidden={!data}
      initial={{
        x: left,
        y: top,
        opacity: 0,
      }}
      animate={{
        x: left,
        y: top,
        opacity: 1,
      }}
      transition={spring}
    >
      <div className="trend__tooltip-content">
        <div className="trend__tooltip-time">
          <Icon name="icon-history" className="trend__tooltip-icon" />
          <div>{formatDate(getSensorDate(data))}</div>
        </div>
        <div className="trend__tooltip-separator" />
        <div className="trend__tooltip-unit">
          <Icon name="cra-dot" className="trend__tooltip-icon" id="trend__tooltip-value-icon" />
          <div>
            <strong>{getSensorValue(data)}</strong> {unit}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const LineAndDot = ({ height, left, top }) => {
  return (
    <Group>
      <motion.line
        x1={0}
        y1={0}
        x2={0}
        y2={height}
        transition={spring}
        initial={{
          x: left,
          opacity: 0,
        }}
        animate={{
          x: left,
          opacity: 1,
        }}
        stroke="currentColor"
        strokeOpacity="0.5"
        strokeWidth="1px"
        pointerEvents="none"
      />
      <motion.circle
        cx={0}
        cy={0}
        transition={spring}
        initial={{
          x: left,
          y: top,
          opacity: 0,
        }}
        animate={{
          x: left,
          y: top,
          opacity: 1,
        }}
        r={3}
        fill="var(--color-observations)"
        pointerEvents="none"
      />
    </Group>
  );
};

export default TrendTooltip;
