import React from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled/macro';

import { Heading } from '../Text/Heading';

const Container = styled(motion.div)`
  top: 0;
  z-index: 30;
  pointer-events: none;
  height: var(--top-bar);
  display: grid;
  align-items: center;
  border-bottom: 1px solid rgba(55, 57, 70, 1);
  background-color: var(--color-darkest);
`;

export default function ListHead({ justify, children, obsHeading }) {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      style={{ '--justify': justify }}
    >
      <Heading className={obsHeading ? 'listHeading' : ''}>{children}</Heading>
    </Container>
  );
}
