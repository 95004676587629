import styled from '@emotion/styled/macro';
import React from 'react';
import { Heading } from '../../components/Text/Heading';
import PreventionItem from './PreventionItem';
import { ReactComponent as IconPrevention } from '../../svgs/icon-shield.svg';

const Box = styled.div`
  --box-padding: 1.2rem;
  --box-border-radius: 10px;
  background-color: var(--color-dark-shade);
  border-radius: var(--box-border-radius);
  z-index: 10;
  align-self: center;
  width: 350px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: calc(100px + 50px);
`;

const Triangle = styled.div`
  position: absolute;
  top: -8px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 8px solid var(--color-dark);
`;

const Head = styled.div`
  background-color: var(--color-dark);
  padding: 0.66em var(--box-padding);
  border-top-right-radius: var(--box-border-radius);
  border-top-left-radius: var(--box-border-radius);
  grid-area: text;
  font-size: var(--font-size-16);
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-template-areas: 'decorator text';
  grid-gap: var(--spacing-10);
  align-items: center;
`;

const List = styled.ul`
  padding: calc(var(--box-padding) / 2);
  list-style: none;
  display: grid;
  grid-gap: 0.1rem;
  margin: 0;
  font-size: var(--font-size-14);
  max-height: 240px;
  overflow-y: auto;
  width: 100%;
`;

const ListItem = styled.li`
  padding: calc(var(--box-padding) / 2);
  border-radius: var(--box-border-radius);
  width: 100%;
  &:hover {
    background-color: var(--color-dark);
  }
`;

export const PreventionBox = (props) => {
  const { preventions } = props;
  return (
    <Box key="box">
      <Triangle />
      <Head>
        <Row>
          <IconPrevention
            stroke="var(--color-actuators)"
            strokeWidth="1"
            fill="none"
            width="1rem"
            height="1.2rem"
          />
          <Heading>Prevent</Heading>
        </Row>
      </Head>
      <List>
        {preventions.map((prevention) => (
          <ListItem key={prevention.actid}>
            <PreventionItem prevention={prevention} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
