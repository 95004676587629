import React, { useMemo } from 'react';

const scaled = function (minValue, maxValue) {
  return function (x) {
    return (maxValue - x) / (maxValue - minValue);
  };
};

function TrendPeakGradient({ id, limits, minValue, maxValue }) {
  const scaleByMinMax = useMemo(() => scaled(minValue, maxValue), [minValue, maxValue]);
  const high = limits.find((h) => h.severity === 'high')?.limit;
  const low = limits.find((h) => h.severity === 'low')?.limit;

  const highLimitCrossed = high && maxValue > high;
  const lowLimitCrossed = low && minValue < low;

  return (
    <linearGradient
      id={id}
      gradientUnits="userSpaceOnUse" // objectBoundingBox doesn't work with simple lines (horisontal or vertical), happens when we have only one value for an observation
      x1="0%"
      y1="0%"
      x2="0%"
      y2="100%"
    >
      {highLimitCrossed && (
        <>
          <stop stopOpacity="1" stopColor="var(--color-observations)" offset="0" />
          <stop
            stopOpacity="1"
            stopColor="var(--color-observations)"
            offset={scaleByMinMax(high) < 1 ? scaleByMinMax(high) : 1}
          />
          <stop
            stopOpacity="1"
            stopColor="currentColor"
            offset={scaleByMinMax(high) < 1 ? scaleByMinMax(high) : 1}
          />
        </>
      )}

      {lowLimitCrossed && (
        <>
          <stop stopOpacity="1" stopColor="currentColor" offset={scaleByMinMax(low)} />
          <stop stopOpacity="1" stopColor="var(--color-observations)" offset={scaleByMinMax(low)} />
          <stop stopOpacity="1" stopColor="var(--color-observations)" offset="1" />
        </>
      )}
      {!lowLimitCrossed && !highLimitCrossed && (
        <>
          <stop stopOpacity="1" stopColor="currentColor" offset="0" />
          <stop stopOpacity="1" stopColor="currentColor" offset="1" />
        </>
      )}
    </linearGradient>
  );
}

export default React.memo(TrendPeakGradient);
