import React, { useEffect } from 'react';

import { BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { timeFormat } from 'd3-time-format';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Icon } from '../../components/common/Icon';
import { extent } from 'd3-array';

import styled from '@emotion/styled/macro';
import { AnimatePresence, motion } from 'framer-motion';

import { useDispatch, useSelector } from 'react-redux';
import useTimeScale from '../../components/trend/useTimeScale';
import { getRankHistory } from '../../store/rankingHistory/rankingHistorySelectors';
import TrendGrid from '../../components/trend/TrendGrid';
import TrendTooltip, { LineAndDot } from '../../components/trend/TrendTooltip';
import { DateTime } from 'luxon';
import { receiveRankingHistory } from '../../store/rankingHistory/rankingHistoryActions';
import { fetchSelectedCauseRankHistory } from '../../store/api';
import { getSelectedCause } from '../../store/ui/uiSelectors';

const StyledRankingWrapper = styled(motion.div)``;

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#191e28',
  display: 'flex',
  flexDirection: 'column',
  color: 'white',
  borderRadius: '15px',
  fontSize: '14px',
  padding: '15px',
  zIndex: '1000',
  minWidth: '225px',
};

const TooltipTime = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1.5em 1fr;
  grid-gap: 0.25rem;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`;

const TooltipValue = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1.5em 1fr;
  grid-gap: 0.25rem;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  & strong span {
    font-weight: 400;
  }
`;

const IconDot = styled.div`
  width: 14px;
  height: 14px;
  margin: 4px;
  border-radius: 50px;
  background-color: ${(props) =>
    props.color === 'orange' ? 'var(--color-observations)' : 'var(--color-gray)'};
`;

const IconHistory = () => {
  return (
    <svg
      width="20px"
      height="20px"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M12,6.09a6,6,0,1,1-6,6,6,6,0,0,1,6-6m0-2a8,8,0,1,0,8,8,8,8,0,0,0-8-8Z"
        fill="#ffffff"
      />
      <path
        d="M15.54,14.29,13,11.76V9.17a1,1,0,0,0-2,0v3a1,1,0,0,0,.08.38,1,1,0,0,0,.22.33l2.83,2.83a1,1,0,1,0,1.41-1.42Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default function SelectedCauseRankHistory({ width, height, functionId }) {
  const dispatch = useDispatch();
  const selected = useSelector(getSelectedCause);
  const rankHistory = useSelector(getRankHistory);
  const { showTooltip, hideTooltip, tooltipData, tooltipTop, tooltipLeft, tooltipOpen } =
    useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
    detectBounds: true,
    debounce: 0,
  });

  useEffect(() => {
    fetchSelectedCauseRankHistory({
      functionId: functionId,
    })
      .then(({ response }) => {
        dispatch(receiveRankingHistory(response));
      })
      .catch(console.error);
  }, [functionId, selected]);

  let data = [];
  let keys = [];

  if (rankHistory.length > 0) {
    data = [
      {
        timestamp: DateTime.fromISO(rankHistory[0].timestamp).minus({ minutes: 30 }).toISO(),
        positive: null,
        neutral: null,
        negative: null,
      },
      ...rankHistory,
    ];
    keys = Object.keys(data[0]).filter((d) => d !== 'timestamp');
  } else {
    data = [];
    keys = [];
  }
  // accessors
  const getDate = (d) => d.timestamp;
  // accessors for rank Line
  const getX = (d) => new Date(d.timestamp);
  const getY = (d) => d.rank;

  const margin = { top: 15, right: 0, bottom: 15, left: 0 };

  // bounds
  const xMax = (width ? width : 0) - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // scales time and rank data into bars on the chart
  const dateScale = scaleBand({
    domain: data.map(getDate),
    padding: 0,
    range: [xMax, 0],
  });

  // saves total height of all bars
  const dataScale = scaleLinear({
    domain: [0, 1],
    round: true,
    range: [yMax, 0],
  });

  // setting colors of stacks in a bar
  const colorScale = scaleOrdinal({
    domain: keys,
    range: [
      'var(--color-observations)', // positive ranking - yellow
      'rgba(45, 47, 60, 0)', // neutral ranking - transparent
      'rgba(194, 200, 204, 0.5)', // negative ranking - light gray
    ],
  });

  // scales for rank Line
  const xScale = scaleTime({
    domain: extent(data, getX),
    range: [0, xMax],
  });
  const yScale = scaleLinear({
    domain: [0, 1],
    range: [yMax, 0],
  });

  // timestamps and format for TrendGrid
  const formatDate = timeFormat('%H:%M');

  const handleTooltip = (event, dataPoint) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    showTooltip({
      tooltipData: {
        timestamp: dataPoint.bar.data.timestamp,
        positive: dataPoint.bar.data.positive,
        negative: dataPoint.bar.data.negative,
      },
      tooltipLeft: x,
      tooltipTop: y - 15,
      tooltipEvent: event,
    });
  };

  return (
    <AnimatePresence initial={false}>
      <StyledRankingWrapper
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        key={`motion`}
        className="mini-trends"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 120 },
          collapsed: { opacity: 0, height: 120 },
        }}
      >
        {rankHistory.length > 0 ? (
          <>
            <svg width={width} height={height} ref={containerRef}>
              {
                //REMOVING RANKING LINE GRAPH FOR NOW TO MAKE THE GRAPH MORE INTUITIVE
              }
              {/* {[rankValue].map((lineData) => {
            return (
              <Group key={`lines`} top={margin.top}>
                <LinePath
                  curve={curveLinear}
                  data={lineData}
                  x={(d) => xScale(getX(d)) ?? 0}
                  y={(d) => yScale(getY(d)) ?? 0}
                  strokeDasharray="3"
                  stroke="#ffffff"
                  strokeWidth={1}
                  strokeOpacity={1}
                  shapeRendering="geometricPrecision"
                />
              </Group>
            );
          })} */}
              <Group top={margin.top}>
                <>
                  <TrendGrid
                    width={xMax}
                    height={yMax}
                    scale={xScale}
                    formatFunction={formatDate}
                    numTicks={6}
                  />
                  <BarStack
                    data={data}
                    keys={keys}
                    x={getDate}
                    xScale={dateScale}
                    yScale={dataScale}
                    color={colorScale}
                  >
                    {(barStacks) =>
                      barStacks.map((barStack) =>
                        barStack.bars.map((bar) => (
                          <rect
                            key={`bar-stack-${barStack.index}-${bar.index}`}
                            x={bar.x}
                            y={bar.y}
                            height={bar.height}
                            width={bar.width}
                            fill={bar.color}
                            onMouseMove={(event) => handleTooltip(event, bar)}
                            onMouseLeave={() => hideTooltip()}
                          />
                        ))
                      )
                    }
                  </BarStack>

                  {tooltipData && tooltipOpen && (
                    <>
                      <LineAndDot top={tooltipTop} left={tooltipLeft} height={yMax} />
                    </>
                  )}
                </>
              </Group>
            </svg>
            {tooltipData && tooltipOpen && (
              <TooltipInPortal
                data={tooltipData}
                top={tooltipTop + 40}
                left={tooltipLeft - 40}
                style={tooltipStyles}
              >
                <TooltipTime>
                  <IconHistory />
                  <div>{formatDate(new Date(tooltipData.timestamp))}</div>
                </TooltipTime>
                <TooltipValue>
                  <IconDot color="orange" />
                  <strong>
                    <span>Supporting observations</span>
                  </strong>
                </TooltipValue>
                <TooltipValue>
                  <IconDot color="gray" />
                  <strong>
                    <span>Conflicting observations</span>
                  </strong>
                </TooltipValue>
              </TooltipInPortal>
            )}
          </>
        ) : (
          <div>No Rank History Available</div>
        )}
      </StyledRankingWrapper>
    </AnimatePresence>
  );
}
