import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDateTime } from '../../store/ui/uiSelectors';

import { scaleTime } from '@visx/scale';
import { motion } from 'framer-motion';

import IconHigh from '../../svgs/cra-obs-high.svg';
import IconLow from '../../svgs/cra-obs-low.svg';
import IconBreach from '../../svgs/cra-breach.svg';
import IconNormal from '../../svgs/cra-normal.svg';

const statusIcon = {
  low: IconLow,
  high: IconHigh,
  breach: IconBreach,
  normal: IconNormal,
  undefined: '',
};

const getStartedDate = (d) => new Date(d.started);
const getLastedDate = (d) => new Date(d.lasted);

const BAR_HEIGHT = 20;
const BAR_HEIGHT_HALF = BAR_HEIGHT / 2;

const LineElement = ({
  x1,
  x2,
  fault,
  faultBadgePosition,
  hideBadge,
  handleGraphClick,
  lasted,
}) => {
  const relativeWidth = x2 - x1;
  const iconHref = statusIcon[fault];

  return (
    <g>
      <motion.g
        initial={{ x: x1 }}
        transition={{
          type: 'tween',
        }}
        animate={{
          x: x1,
        }}
      >
        <rect
          width={relativeWidth < 0 ? 0 : relativeWidth}
          height={BAR_HEIGHT}
          y="0"
          rx={BAR_HEIGHT_HALF}
          fill={fault === 'normal' ? 'var(--color-gray)' : 'rgba(255, 255, 255, 0.2)'}
        />
      </motion.g>
      <motion.g
        style={{ cursor: 'pointer' }}
        transition={{ type: 'tween' }}
        animate={{
          x: faultBadgePosition - (BAR_HEIGHT + 1),
          display: !hideBadge ? 'block' : 'none',
        }}
        initial={{ x: faultBadgePosition - (BAR_HEIGHT + 1) }}
        onClick={(e) => handleGraphClick(lasted)}
      >
        <circle
          cx={BAR_HEIGHT_HALF}
          cy={BAR_HEIGHT_HALF}
          r={BAR_HEIGHT_HALF}
          fill={fault === 'normal' ? 'var(--color-gray-light)' : 'var(--color-observations)'}
        />
        <image
          xlinkHref={iconHref}
          href={iconHref}
          x="0"
          y="0"
          width={BAR_HEIGHT}
          height={BAR_HEIGHT}
        />
      </motion.g>
    </g>
  );
};

const TimelineTrack = ({ timeline, width, handleGraphClick }) => {
  const { now, then } = useSelector(getDateTime);

  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [0, width],
        domain: [then, now],
        clamp: false,
      }),
    [width, now]
  );

  const dateScaleForFaultBadges = useMemo(
    () =>
      scaleTime({
        range: [0, width],
        domain: [then, now],
        clamp: true,
      }),
    [width, now]
  );

  return (
    <div className="timeline__track">
      <svg className="timeline__track-graph" height={BAR_HEIGHT}>
        {width &&
          timeline.map((track, index) => {
            const { started, id, fault, lasted } = track;
            const key = `${started}-${fault}-${id}-${index}`;

            const x1 = dateScale(getStartedDate(track));
            const x2 = dateScale(getLastedDate(track));
            const badgePosition = dateScaleForFaultBadges(getLastedDate(track));
            return (
              <LineElement
                x1={x1}
                x2={x2}
                key={key}
                fault={fault}
                faultBadgePosition={badgePosition}
                hideBadge={x1 > width}
                handleGraphClick={handleGraphClick}
                lasted={lasted}
              />
            );
          })}
      </svg>
    </div>
  );
};
export default TimelineTrack;
