import React, { useRef } from 'react';
import styled from '@emotion/styled/macro';
import { timeFormat } from 'd3-time-format';

import useTimescale from '../../hooks/useTimescale';
import TimelineAxis from './TimelineAxis';

import Tick from './Ticks/Tick';

const StyledTimelineSegments = styled.svg`
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const StyledInnerSvg = styled.svg`
  overflow: hidden;
`;

const margin = { top: 0, bottom: 0, left: 0, right: 0 };
const formatDate = timeFormat('%H:%M');

export default function TimelineSegments({ now }) {
  const timelineRef = useRef(null);
  const [dateScale, width, height] = useTimescale(now, timelineRef);

  const xMax = width - margin.left - margin.right;

  return (
    <div className="timeline__hours" ref={timelineRef}>
      {width && height && (
        <StyledTimelineSegments>
          <StyledInnerSvg width="100%" height="100%">
            <TimelineAxis timelineScale={dateScale} now={now} />
          </StyledInnerSvg>

          <Tick
            x={xMax}
            key="now"
            color="#fff"
            width={2}
            opacity={0.6}
            text={formatDate(now.ts)}
          ></Tick>
        </StyledTimelineSegments>
      )}
    </div>
  );
}
