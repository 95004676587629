import { Interval } from 'luxon';

export function getOverlappingObservationsByDates(timeline, x0, x1) {
  const timeWindow = Interval.fromDateTimes(x0, x1);
  const observationsOverlappingWithScrubberWindow = timeline.filter(({ observations }) => {
    const overlaps = observations.filter(({ started, lasted }) => {
      const entry = Interval.fromDateTimes(new Date(started), new Date(lasted));
      return entry.overlaps(timeWindow);
    });
    return overlaps.length;
  });

  return observationsOverlappingWithScrubberWindow;
}
