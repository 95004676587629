import React from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import HelpPageBackground from './HelpPageBackground';
import HelpScreenLabel from './HelpScreenLabel';
import { getCauses, getConsequences, getCorrectiveActions } from '../../store/graph/graphSelectors';


const HelpKeyButton = styled.button`
  height: var(--top-bar);
  background-color: var(--color-charcoal);
  border-radius: 10px;
  border: 1px solid var(--color-yellow);
  align-self: center;
  height: 45px;
  width: 215px;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999999;
  text-align: center;
  ${({ endAlign }) => endAlign && `
    align-self: end;
    // border-left: 330px solid transparent;
  `}
`;

const HelpGroup = ({toggleHelp, toggleHelpKeyPanel}) => {
    const causes = useSelector(getCauses);
    const consequences = useSelector(getConsequences);
    const corrections = useSelector(getCorrectiveActions);

    const preventionsHelpMessage = 'Prevention steps are listed here that can stop the propagation of a fault. These are useful for quickly reacting to prevent severe end consequences from happening.';
    const timelineMainMessage =
        'The timeline shows the ongoing sensor observations in deviation over the past 30 minutes. The sensor value graphs can be expanded by clicking on a timeline observation.';
    const rewindHelpMessage =
        'Rewind mode can be toggled to review previous scenarios. This can be useful when time is needed to review a situation in more detail at a particular point in time.';

    const observationsHelpMessage = "The observations area displays the sensors currently in deivation which are related to the selected root cause.";
    const selectedCauseMessage = 'The root cause selected by the user will be displayed here.';
    const causesListMessage =
        'Other root causes are shown in a list below the selected cause. They can be selected to highlight the related observations and consequences.';

    const consequencesExistHelpMessage =
        'The consequences section displays the consequences which are related to the currently selected root cause. Consequences can be selected as well to highlight the related observations in blue in the supporting observations area.';
    const noConsequencesExistHelpMessage =
        'The consequences section displays the consequences related to the currently selected root cause. There are currently no related consequences to display.';
    const manualVerificationMessage = "Instrument deviations can be input here by manually verifying gauge levels. The manually verified observations are treated just like those from transmitters, and are considered in reasoning results.";
    const correctionsMessage = "Correction steps may be taken to address root causes that may occur.";
    const equipmentMessage = "The list of out of operation equipment can be found here. If equipment is out of operation, related causes, consequences, and observations are removed from reasoning results."

    return (
        <>
            <HelpPageBackground className="" onOverlayClick={toggleHelp}></HelpPageBackground>
            <HelpKeyButton onClick={toggleHelpKeyPanel} endAlign={causes.length > 0 ? false : true}>Color and Icon Legend</HelpKeyButton>
            <HelpScreenLabel
                message={equipmentMessage}
                rightOffset={150}
                topOffset={30}
            ></HelpScreenLabel>
            <HelpScreenLabel
                message={timelineMainMessage}
                bottomOffset={'calc(var(--observation-body-height) + 5px)'}
                leftOffset={0}
                rightOffset={0}
                type="text"
                label="Timeline"
            ></HelpScreenLabel>
            <HelpScreenLabel
                message={rewindHelpMessage}
                rightOffset={40}
                bottomOffset={'calc(var(--observation-body-height) + 5px)'}
                labelLeftOrRight={'left'}
            ></HelpScreenLabel>
            {causes.length > 0 &&
                <>
                <HelpScreenLabel
                    message={observationsHelpMessage}
                    topOffset={220}
                    leftOffset={250}
                    labelLeftOrRight={'left'}
                    rightOffset={0}
                ></HelpScreenLabel>
                <HelpScreenLabel
                    type="text"
                    label={'Verify'}
                    message={manualVerificationMessage}
                    topOffset={200}
                    rightOffset={'69%'}
                    labelLeftOrRight={'left'}
                ></HelpScreenLabel>
                <HelpScreenLabel
                    message={preventionsHelpMessage}
                    className="layout-help-label"
                    topOffset={200}
                    type="text"
                    rightOffset={'26%'}
                    label="Preventions"
                ></HelpScreenLabel>
                </>
            }
            <HelpScreenLabel
                label="Root Cause"
                message={selectedCauseMessage}
                topOffset={185}
                leftOffset={270}
            ></HelpScreenLabel>
            <HelpScreenLabel 
                message={causesListMessage} 
                topOffset={70} 
                leftOffset={30}
                label={"Root Causes"}
                type="text"
            ></HelpScreenLabel>
            {consequences.length > 0 &&
                <HelpScreenLabel
                type="text"
                label={'Consequences'}
                message={consequencesExistHelpMessage}
                topOffset={102}
                rightOffset={223}
                labelLeftOrRight={'left'}
                ></HelpScreenLabel>
            }
            
            {consequences.length === 0 && <HelpScreenLabel
                message={noConsequencesExistHelpMessage}
                topOffset={200}
                rightOffset={60}
                type='text'
                label="Consequences"
                labelLeftOrRight={'left'}
            ></HelpScreenLabel> }
            {/* <HelpScreenLabel
                message={verifyCauseMessage}
                topffset={400}
                leftOffset={300}
            ></HelpScreenLabel> */}
            {corrections.length > 0 &&
                <HelpScreenLabel
                type="text"
                label={'Corrections'}
                message={correctionsMessage}
                topOffset={'25%'}
                leftOffset={'13%'}
                labelLeftOrRight={'left'}
                ></HelpScreenLabel>
            }
          </>
    );
}

export default HelpGroup;