import React, { useCallback, useEffect, useRef } from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import './Tooltip.css';
import Store from '../../containers/Store';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import { ReactComponent as EyeIcon } from '../../svgs/cra-eye.svg';
import { keyframes } from '@emotion/react';
import { Heading } from '../../components/Text/Heading';

const pulse = keyframes`
0% {
  box-shadow: 0 0 0 0 rgba(	231,	255,	75, 0.6);

}
70% {
    box-shadow: 0 0 0 10px rgba(231,	255,	75, 0);

}
100% {
    box-shadow: 0 0 0 0 rgba(231,	255,	75, 0);
}
`;

const StyledEyeButton = styled.button`
  border-radius: 16px;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border: 1px solid var(--color-yellow);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  background-color: rgba(45,47,60, 0.7);
  animation: 1s ${pulse} ease-out infinite;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const StyledHeadingWrapper = styled(Heading)`
  padding: 0 var(--spacing-10);
`;

const StyledLabel = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const HelpScreenLabel = ({
  children,
  message,
  topOffset,
  bottomOffset,
  leftOffset,
  rightOffset,
  type = 'icon',
  label,
}) => {
  const helpPageOpen = useSelector((state) => state.ui.uiHelpPageOpen);

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
  });

  const { showTooltip, hideTooltip, tooltipOpen, tooltipData, tooltipLeft, tooltipTop } =
    useTooltip({
      tooltipOpen: false,
    });

  const checkIfPercent = (candidate) => {
    return /^(\d+|(\.\d+))(\.\d+)?%$/.test(candidate)
  }

  const tooltipStyles = {
    ...defaultStyles,
    // backgroundColor: 'var(--color-charcoal)',
    backgroundColor: 'rgba(55,57,70, 0.9)',
    color: 'white',
    padding: 15,
    transition: 'opacity 0.6s ease-in-out 0s',
    zIndex: 99999,
    maxWidth: '250px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  };

  const elementRef = useRef(null);

  return (
    <>
      <div 
        className={`help-tooltip`}
        style={{
          position: 'absolute',
          // top: !isNaN(topOffset) ||  checkIfPercent(topOffset) ? topOffset : 'unset',
          // right: !isNaN(rightOffset) || checkIfPercent(rightOffset) ? rightOffset : 'unset',
          // left: !isNaN(leftOffset) || checkIfPercent(leftOffset) ? leftOffset : 'unset',
          // bottom: !isNaN(bottomOffset) || checkIfPercent(bottomOffset) ?  bottomOffset : 'unset',
          top: topOffset !== null && topOffset !== "" ? topOffset : 'unset',
          right: rightOffset !== null && rightOffset !== "" ? rightOffset : 'unset',
          left: leftOffset !== null && leftOffset !== "" ? leftOffset : 'unset',
          bottom: bottomOffset !== null && bottomOffset !== "" ?  bottomOffset : 'unset',
          // bottom: "var(--observation-body-height)",
          // color: "var(--color-charcoal)",
          zIndex: 9999999999,
          margin: 'auto',
          width: 'fit-content',
          opacity: 0.8,
        }}
      >
        {helpPageOpen && (
          <StyledEyeButton
            ref={elementRef}
            onMouseEnter={(e) => showTooltip({ tooltipLeft: e.clientX, tooltipTop: e.clientY })}
            onMouseLeave={() => hideTooltip()}
          >
            {type === 'icon' ? 
              <EyeIcon /> : 
              <StyledHeadingWrapper>
                <StyledLabel>{label}</StyledLabel>
              </StyledHeadingWrapper>
            }
          </StyledEyeButton>
        )}
        {children}
      </div>
      {helpPageOpen && tooltipOpen && (
        <TooltipInPortal
          key={Math.random()}
          left={tooltipLeft}
          top={tooltipTop}
          style={tooltipStyles}
          className='help-tooltip-box'
        >
          <span>{message}</span>
        </TooltipInPortal>
      )}
    </>
  );
};

export default HelpScreenLabel;
