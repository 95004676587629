import { useState } from 'react';

const useVisibleCardsCount = (length, step = 5, initial = 10) => {
  const [increment, setIncrement] = useState(0);

  function increaseVisibles() {
    setIncrement((i) => i + 1);
  }

  function resetVisibles() {
    setIncrement(0);
  }

  const count = initial + increment * step;

  return [Math.min(count, length), increaseVisibles, resetVisibles];
};

export default useVisibleCardsCount;
