import { createSelector } from 'reselect';
import { find, groupBy, last, map } from 'lodash';
import { getTimestamp } from '../../ui/uiSelectors';

const equipments = (state) => state.api.equipment.events;
export const equipmentConfig = (state) => state.api.equipment.config;
const notificationsSelector = (state) => state.notifications;

export const getNotifications = createSelector(
  equipments,
  notificationsSelector,
  ({ equipment, notifications }) => {
    return notifications.map((n) => {
      const eq = find(equipment, ['equipmentId', n.equipmentId]);
      return {
        ...eq,
        status: n.status,
        occurredOn: n.occurredOn,
      };
    });
  }
);

export const getEquipmentStatuses = createSelector(equipments, (equipment) => {
  return equipment;
});

export const selectEquipments = createSelector(equipments, (equipment) => {
  return equipment;
});

export const selectEquipmentByTime = createSelector(
  selectEquipments,
  getTimestamp,
  (equipments, timestamp) => {
    const filteredByTimestamp = equipments.filter(
      (equipment) => new Date(equipment.timestamp) < new Date(timestamp)
    );
    const grouped = groupBy(filteredByTimestamp, (equipment) => equipment.equipmentId);

    return map(grouped, last);
  }
);

export const selectEquipmentNotInOperation = createSelector(selectEquipmentByTime, (equipments) => {
  return equipments.filter((equipment) => equipment.status === 'not_running');
});

export const selectEquipmentInOperation = createSelector(
  (state) => state.api.equipment,
  (equipments) => equipments.filter((equipment) => equipment.status === 'running')
);

export const selectEquipmentGroup = createSelector(selectEquipments, (equipments) => {
  const grouped = groupBy(equipments, (equipment) => equipment.equipmentId);
  return grouped;
});
