import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled/macro';

const StyledCount = styled(motion.div)`
  margin-left: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: #2d2f3c;
  grid-template-areas: 'number';
`;

const StyledCountNumber = styled(motion.div)`
  grid-area: number;
`;

const CountCircle = ({ count }) => {
  return (
    <StyledCount>
      <AnimatePresence initial="false">
        <StyledCountNumber
          initial={{ opacity: 0, y: '100%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '-100%' }}
          transition={{ type: 'tween' }}
          key={'count' + count}
        >
          {count}
        </StyledCountNumber>
      </AnimatePresence>
    </StyledCount>
  );
};

export default CountCircle;
