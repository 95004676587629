import React, { useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import { getCauses } from '../../store/graph/graphSelectors';
import {
  getHighlightsBasedOnHoveredObservationId,
  getSelectedCause,
  getCorrectiveActionsForSelectedCause,
} from '../../store/ui/uiSelectors';
import { cardClick } from '../../store/ui/uiActions';

import MainList from '../List';
import ListHead from '../../components/List/ListHead';
import SelectedCause from './SelectedCause';
import { motion, AnimateSharedLayout } from 'framer-motion';
import _, { orderBy } from 'lodash';
import Card from '../../components/cards/Card';
import { Counteraction } from './Counteraction';
import {
  fetchSelectedCauseRankHistory,
  postCauseOrConsequenceClickedMetrics,
  postSessionClosedMetrics,
} from '../../store/api';
import { receiveRankingHistory } from '../../store/rankingHistory/rankingHistoryActions';
import { ReactComponent as IconTopBarLogo } from '../../svgs/topbar-logo.svg';
import HelpScreenLabel from '../../components/common/HelpScreenLabel';

const ConsequencesContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;
  overflow: hidden;
`;

const StyledCausesListContainer = styled(motion.div)`
  display: grid;
  grid-template-rows: ${(props) =>
    props.withCounteractions
      ? 'max-content max-content max-content 1fr'
      : 'max-content max-content 1fr'};
  height: 100%;
  overflow: hidden;
`;

const StyledSelectedCausesLOL = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-20) var(--spacing-30);
`;

const StyledHeading = styled.h3`
  color: var(--color-gray-light);
  font-size: var(--font-size-14);
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
`;

const StyledClearButton = styled.button`
  color: var(--color-observations);
  font-size: var(--font-size-16);
`;

const Causes = () => {
  const causes = useSelector(getCauses);
  const { COUNTERACTIONS } = useSelector((state) => state.api.flags);
  const sessionId = useSelector((state) => state.api.sessionId);
  const helpPageOpen = useSelector((state) => state.ui.uiHelpPageOpen);
  const dispatch = useDispatch();
  const { highlightedCauses } = useSelector(getHighlightsBasedOnHoveredObservationId);

  const selected = useSelector(getSelectedCause);
  const corrective = useSelector(getCorrectiveActionsForSelectedCause);

  const handleOnCardClick = async (uuid, counteractions, clearSelected) => {
    if (clearSelected) {
      dispatch(cardClick({ cause: null }));
    } else {
      const fCause = causes.find((c) => c.uuid === uuid);
      dispatch(cardClick({ cause: uuid }));
      await postCauseOrConsequenceClickedMetrics({
        session_id: sessionId,
        id: fCause.functionId,
        rank: fCause.rank,
        state: fCause.state,
        action_name: 'CAUSE_CLICKED',
        reason: 'MANUAL',
      });
    }
  };

  const clearSelected = useCallback(() => {
    dispatch(cardClick({ cause: null }));
  }, []);

  // useEffect(() => {
  //   return async () => {
  //     await postSessionClosedMetrics(sessionId, 'SESSION_CLOSED', null);
  //   };
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (causes.length > 0) {
        if (!selected) {
          dispatch(cardClick({ cause: causes[0].uuid }));
          await postCauseOrConsequenceClickedMetrics({
            session_id: sessionId,
            id: causes[0].functionId,
            rank: causes[0].rank,
            state: causes[0].state,
            action_name: 'CAUSE_CLICKED',
            reason: 'AUTO',
          });
        }
      }
    };
    fetchData();
  }, [causes, selected]);


  return (
    <ConsequencesContainer>
      <ListHead>
        <IconTopBarLogo></IconTopBarLogo>
      </ListHead>
      <AnimateSharedLayout>
        <StyledCausesListContainer
          withCounteractions={selected && corrective && corrective.length > 0 ? true : false}
        >
          <StyledSelectedCausesLOL>
            { !helpPageOpen ?
              <StyledHeading>Selected Cause</StyledHeading> : 
              <StyledHeading>&nbsp;</StyledHeading>
            }
          </StyledSelectedCausesLOL>
         
          {selected ? (
            <SelectedCause.Selected selected={selected} />
          ) : (
            <SelectedCause.BlankState />
          )}
          {selected && COUNTERACTIONS && corrective && corrective.length > 0 && (
            <Counteraction counteractions={corrective} />
          )}
          <MainList list={causes} listName="cause" highlight={highlightedCauses}>
            {causes.map((item) => {
              const isSelected = item.uuid === selected?.uuid;
              return (
                <Card
                  type={'cause'}
                  key={item.uuid}
                  isSelected={isSelected}
                  data={item}
                  label={item.label}
                  id={item.uuid}
                  onCardClick={() => handleOnCardClick(item.uuid, isSelected)}
                  count={item.observationFunctions.length}
                  labels={item.state}
                  {...item}
                />
              );
            })}
          </MainList>
        </StyledCausesListContainer>
      </AnimateSharedLayout>
    </ConsequencesContainer>
  );
};

export default Causes;
