import { takeLatest, call } from 'redux-saga/effects';
import { answerClick } from './verifyCauseActions';
import { postAnswer } from '../api';

function* answerClicked({ payload }) {
  const { answer, ttl: ttlValue } = payload;
  const ttl = answer === 'clear' ? 0 : ttlValue || 30;
  payload.ttl = ttl;
  yield call(postAnswer, payload);
}

function* watchAnswerClicks() {
  yield takeLatest(answerClick, answerClicked);
}

export default function* verifyCauseSagas() {
  yield watchAnswerClicks();
}
