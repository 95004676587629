import React from 'react';

import { useSelector } from 'react-redux';
import { isNormal } from '../store/ui/uiSelectors';

import { motion, AnimatePresence } from 'framer-motion';
import { Icon } from '../components/common/Icon';
import './Circle.css';

const CONSTANTS = {
  GREEN: '#50e3c2',
  GRAY: '#373946',
};

const container = {
  initial: {
    color: CONSTANTS.GRAY,
    transition: {
      duration: 2,
      ease: [0.65, -0.06, 0.52, 0.96],
    },
  },
  idle: {
    color: [null, CONSTANTS.GREEN],
    transition: {
      duration: 2,
      ease: [0.65, -0.06, 0.52, 0.96],
    },
  },
  empty: {
    color: CONSTANTS.GRAY,
    transition: {},
  },
  selected: {
    color: CONSTANTS.GRAY,
    transition: {},
  },
};

const logo = {
  initial: {
    opacity: 0,
  },
  idle: {
    opacity: 1,
  },
  empty: {
    opacity: 0,
  },
  selected: {
    opacity: 0,
  },
};

const circles = {
  idle: (i) => ({
    scale: [1.1 * i, 0.8 * i],
    fillOpacity: [0.1 * i, 0.1],
    strokeOpacity: 0.5,
    transition: {
      repeatType: 'reverse',
      repeat: Infinity,
      ease: [0.65, -0.06, 0.52, 0.96],
      duration: 2,
    },
  }),
  empty: () => ({
    scale: [null, 0.6],
    strokeOpacity: 0,
    fillOpacity: 0.1,
    transition: {
      duration: 0.5,
    },
  }),
  selected: () => ({
    scale: [null, 0.9],
    strokeOpacity: 0.1,
    fillOpacity: 0.2,
    transition: {
      ease: 'circOut',
      duration: 1,
    },
  }),
};

const text = {
  initial: {
    opacity: 0,
  },
  idle: {
    opacity: 0,
  },
  empty: {
    opacity: 0.6,
    y: '0%',
    transition: {
      delay: 0.3,
    },
  },
  selected: {
    opacity: 0,
    y: '50%',
    transition: {
      duration: 0.1,
    },
  },
};

const Circles = ({ isNotEmpty }) => {
  const isCurrentlyNormal = useSelector(isNormal);
  const mode = !isCurrentlyNormal ? (!isNotEmpty ? 'empty' : 'selected') : 'idle';

  return (
    <AnimatePresence>
      <motion.div
        key="gray-small"
        animate={mode}
        variants={container}
        exit="initial"
        className="circle"
        initial={'initial'}
      >
        <motion.div className="circle__logo" variants={logo}>
          <Icon name="logo" className="circle__logo-svg" />
        </motion.div>
        <svg
          className="circle__pulsar circle--pulse"
          viewBox="0 0 10% 10%"
          width="400"
          height="400"
          preserveAspectRatio="xMidYMid slice"
        >
          <motion.circle
            cx="50%"
            cy="50%"
            r="50%"
            animate={mode}
            variants={circles}
            fillOpacity={0}
            strokeOpacity={0.1}
            custom={0.85}
            className="circle__shade"
          />
          <motion.circle
            cx="50%"
            cy="50%"
            r="40%"
            animate={mode}
            variants={circles}
            custom={0.9}
            fillOpacity={0}
            strokeOpacity={0.1}
            className="circle__shade"
          />
          <motion.circle
            cx="50%"
            cy="50%"
            r="30%"
            animate={mode}
            variants={circles}
            custom={1}
            fillOpacity={0}
            strokeOpacity={0.1}
            className="circle__shade"
          />
        </svg>
        <motion.div variants={text} animate={mode} initial="initial" className="circle__text">
          Select a cause to view supporting observations
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Circles;
