import React from 'react';
import './Card.css';
import Icon from '../common/Icon';

const CardButton = ({ onCardClick, disabled, onMouseEnter, onMouseLeave, title, type }) => {
  const handleOnCardClick = () => {
    onCardClick();
  };

  return (
    <button
      className={`card-button-wrapper card-button-disabled-${disabled}`}
      type="button"
      onClick={handleOnCardClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      data-id={title}
    >
      <div className={`card-button card card-cause`}>
        <div className="card-body text-ellipsis">
          <span className="text-bold text-ellipsis">{title}</span>
          <Icon name={`kairos-icon-${type}`} className={`card-button-icon ${disabled}`} />
        </div>
      </div>
    </button>
  );
};

export default CardButton;
