import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as IconUmbrella } from '../../svgs/cra-umbrella.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { postPreventMetrics } from '../../store/api';

const Icon = styled.div`
  color: var(--color-actuators);
  margin-right: 0.6rem;
`;

const TextAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChoicesWrapper = styled(motion.div)`
  padding-top: 0.8rem;
  overflow: hidden;
  width: 100%;
`;

const ChoicesAnimatedWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 25px;
`;

const PreventionLabelAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const PreventionItem = (props) => {
  const { prevention } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const sessionId = useSelector((state) => state.api.sessionId);

  const handleExpandClick = async () => {
    setIsExpanded(!isExpanded);
    await postPreventMetrics(sessionId, 'PREVENT_EXPANDED');
  };

  return (
    <ItemWrapper>
      <TextAndIconWrapper onClick={() => handleExpandClick()}>
        <Icon>
          <IconUmbrella
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            width="1rem"
            height="1rem"
          />
        </Icon>
        <PreventionLabelAndDescription>
          <span>{prevention.actuatorTag}</span>
          <span style={{ fontSize: 14 }} className="color-gray">
            {prevention.description}
          </span>
        </PreventionLabelAndDescription>
      </TextAndIconWrapper>
      {/*enable after backend*/}
      <AnimatePresence>
        {isExpanded && (
          <ChoicesWrapper
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ type: 'tween' }}
          >
            <ChoicesAnimatedWrapper>
              <span style={{ fontSize: 12 }}>{prevention.action}</span>
            </ChoicesAnimatedWrapper>
          </ChoicesWrapper>
        )}
      </AnimatePresence>
    </ItemWrapper>
  );
};

export default PreventionItem;
