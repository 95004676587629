import { call, fork, put } from 'redux-saga/effects';

import modeSagas from './mode/sagas';
import apiSagas, { authSaga } from './api/sagas';
import uiSagas from './ui/uiSagas';
import timelineSagas from './timeline/timelineSagas';
import verifyCauseSagas from './verifyCause/verifyCauseSagas';
import sensorsSagas from './sensors/sensorsSagas';
import { doBootSequence } from './api/actions';

export function* mainSaga() {
  // using call() is blocking until it has finished loading.
  yield call(apiSagas);

  // // thus, apiSagas need to be completed before doBootSequences is fired.
  // // When it is fired though, we start the tasks of fetching data and setting up the WS connections.
  yield put(doBootSequence());

  // // Once doBootSequence action is fired, we set up the sagas that needs the boot sequence to be completed.
  // /*   yield fork(equipmentSagas); */
  yield fork(modeSagas);
  yield fork(timelineSagas);
  yield fork(uiSagas);
  yield fork(verifyCauseSagas);
  // yield fork(sensorsSagas);
}
