import styled from '@emotion/styled/macro';
import { Line, LinePath, Polygon } from '@visx/shape';
import { curveBasis } from 'd3-shape';
import React from 'react';

const StyledSvg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
`;
export const MitigationsLines = (props) => {
  const { width, height, withCounteractions, withPreventions, selectedConsequence } = props;

  //lines shape configuration
  const data = [
    [width, 70],
    [width - 56, 70],
    [width - 56, 80],
    [width / 2 + 60, 113],
    [width / 2 + 55, 113],
    [width / 2, 113],
    [0, 113],
  ];

  return (
    <StyledSvg>
      {withCounteractions && (
        <Polygon center={{ x: width - 5, y: 70 }} rotate={180} sides={3} size={10} fill="#2D2F3C" />
      )}
      <LinePath data={data} curve={curveBasis} stroke="#2D2F3C" strokeWidth={2} />

      {withPreventions && withCounteractions && selectedConsequence && (
        <Line
          strokeWidth={2}
          from={{ x: width / 2, y: 120 }}
          to={{ x: width / 2, y: 100 + 50 }}
          strokeDasharray="2"
          stroke="#2D2F3C"
        />
      )}
    </StyledSvg>
  );
};
