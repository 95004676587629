import React, { useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';

const Background = styled.div`
  background: var(--color-darkest);
  opacity: 0.4;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 99999;
`;

const HelpPageBackground = ({onOverlayClick}) => {
    useEffect(() => {
        const handleClick = (event) => {
          if (event.target.id === 'overlay') {
            onOverlayClick();
          }
        };
    
        document.addEventListener('click', handleClick);
    
        return () => {
          // Clean up the event listener
          document.removeEventListener('click', handleClick);
        };
    }, [onOverlayClick]);

    return (
        <Background id='overlay'>

        </Background>
    );
};

export default HelpPageBackground;
