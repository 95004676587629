import React, { useState, useEffect, useRef } from 'react';

export const Icon = ({name, ...rest }) => {
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./../../svgs/${name}.svg`)
        ).default;
      } finally {
        if (!isCancelled) {
          setLoading(false);
        }
      }
    };
    importIcon();

    return () => {
      isCancelled = true;
    };
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon }=  ImportedIconRef;
    return <ImportedIcon {...rest} />;
  }

  return null;
};

export default Icon;
