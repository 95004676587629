import React from 'react';
import { Text } from '@visx/text';
import { Line } from '@visx/shape';
import { Group } from '@visx/group';

const TrendLimits = ({ limits, scale, xMax, unit = '', hideLimitLabels = false }) => {
  const [start, stop] = scale.domain();

  const Limits = limits
    // This is a work around for when sensor data are exactly ONLY the same as one of the limimts.
    // if scale domain is, say [5,5], and one of the limits is exactly that, use only that one.
    .filter(({ limit }) => !(start === stop && limit !== start))
    .map(({ limit, severity }) => {
      const posY = scale(limit);
      return (
        <Group className="trend__limits" key={severity}>
          <Line
            className="trend__limits-line"
            from={{ x: 0, y: posY }}
            to={{ x: xMax, y: posY }}
            strokeDasharray="6,4"
            stroke="#FFFFFF"
            strokeOpacity="0.2"
            strokeWidth="1"
            data-hidden={posY < 0 || posY > 100}
          />

          {!hideLimitLabels && (
            <>
              <use x={xMax} y={posY} href={`#${severity}`} transform="translate(0, -14)" />
              <Text
                className="trend__limits-text"
                x={xMax + 20}
                y={posY}
                verticalAnchor="middle"
                data-hidden={posY < 0 || posY > 100}
                transform="translate(10 0)"
              >
                {`${limit.toFixed(1)} ${unit}`}
              </Text>
            </>
          )}
        </Group>
      );
    });

  return Limits;
};

export default React.memo(TrendLimits);
/* export default React.memo(TrendLimits); */
