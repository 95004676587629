import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EquipmentNotifications, EquipmentNotification } from './EquipmentNotification';
import EquipmentList from './EquipmentList';

import { getNotifications } from '../../store/equipment/equipmentSelectors';
import { dismissNotification } from '../../store/equipment/equipmentActions';
import { selectEquipments } from '../../store/api/selectors/equipment';
import { connect } from 'react-redux';

const Equipment = ({ notifications, dismissNotification, uiEquipmentPanelOpen }) => {
  //remove this way of doing visibility when equipment menu is part of redux
  const [notificationsVisible, setNotificationsVisible] = useState(true);

  const equipments = useSelector(selectEquipments);
  const dismissAlert = (notificationToDismiss) => {
    dismissNotification(notificationToDismiss);
  };

  //remove this way of doing visibility when equipment menu is part of redux
  const toggleNotificationVisibility = () => {
    setNotificationsVisible(!notificationsVisible);
  };

  return (
    <div className="notifications">
      <div className="notifications-bar" />
      <EquipmentList
        list={equipments}
        statuses={equipments.filter((item) => item.status === 'not_running')}
        toggleNotificationVisibility={toggleNotificationVisibility}
        isVisible={uiEquipmentPanelOpen}
      />
      {
        //remove this way of doing visibility when equipment menu is part of redux
        notificationsVisible && (
          <EquipmentNotifications list={notifications.reverse()} isVisible={!uiEquipmentPanelOpen}>
            {(item) => (
              <EquipmentNotification
                operational={item.status === 'running'}
                key={item.equipmentId + item.timestamp}
                equipment={item}
                onNotificationClick={dismissAlert}
              />
            )}
          </EquipmentNotifications>
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
  uiEquipmentPanelOpen: state.ui.uiEquipmentPanelOpen,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dismissNotification: (index) => dispatch(dismissNotification(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Equipment);
