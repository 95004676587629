import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postVerifyCauseMetrics } from '../../store/api';
import { getModel } from '../../store/api/selectors/model';

import { getSensorsAndAnswers } from '../../store/ui/uiSelectors';
import { answerClick } from '../../store/verifyCause/verifyCauseActions';

const VerifySensorBoxAnswer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* border: solid 1px var(--color-charcoal-light, currentColor); */
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 25px;
`;

const VerifySensorButton = styled.button`
  border: solid 1px var(--color-charcoal-light, currentColor);
  font-size: 0.8rem;
  font-weight: bold;
  padding: 5px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;
  &:first-of-type {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-of-type {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.active {
    border-color: transparent;
    background-color: ${(props) => {
      if (props.value === 'normal') {
        return 'var(--color-gray)';
      } else {
        return 'var(--color-observations)';
      }
    }};
    color: #2d303b;
    margin: 2px 0;
  }
`;

const VerifyManualSensor = ({ sensorFunctionId, status }) => {
  const dispatch = useDispatch();
  const model = useSelector(getModel);
  const answersAndSensors = useSelector(getSensorsAndAnswers);

  const answers = answersAndSensors.answers;
  const sensors = answersAndSensors.sensors;
  const sessionId = useSelector((state) => state.api.sessionId);

  const matchedFunction = useMemo(
    () => Object.values(model).find((item) => sensorFunctionId === item.functionId),
    [sensorFunctionId, model]
  );

  const matchedSensor = useMemo(() => {
    const sensor = sensors.find((item) => item.function.id === matchedFunction);
    const timeSpare = 30;

    return sensor ? sensor.ttl || timeSpare : timeSpare;
  }, [sensors, matchedFunction]);

  const choooseAnswer = async (ttlValue, id, answer, isNormal) => {
    if (status === 'RECENT' && answer === 'clear') {
      return;
    } else {
      dispatch(answerClick({ ttl: ttlValue, functionId: id, answer, isNormal }));
      await postVerifyCauseMetrics(sessionId, 'VERIFY_CAUSE_ENTERED');
    }
  };

  if (!matchedFunction) {
    return null;
  }
  return (
    <>
      <VerifySensorBoxAnswer>
        {matchedFunction.concept === 'bar' ? (
          <>
            <VerifySensorButton
              className={`verify-sensor-button ${
                answers[matchedFunction.functionId] === 'normal' ? 'active' : ''
              }`}
              onClick={() =>
                choooseAnswer(
                  matchedSensor,
                  matchedFunction.functionId,
                  answers[matchedFunction.functionId],
                  true
                )
              }
              value="normal"
            >
              Normal
            </VerifySensorButton>
            <VerifySensorButton
              className={`verify-sensor-button ${
                answers[matchedFunction.functionId] === 'breach' ? 'active' : ''
              }`}
              onClick={() => choooseAnswer(matchedSensor, matchedFunction.functionId, 'breach')}
              value="breach"
            >
              Breach
            </VerifySensorButton>
          </>
        ) : (
          <>
            <VerifySensorButton
              className={`verify-sensor-button ${
                answers[matchedFunction.functionId] === 'low' ? 'active' : ''
              }`}
              onClick={() => choooseAnswer(matchedSensor, matchedFunction.functionId, 'low')}
              value="low"
            >
              Low
            </VerifySensorButton>
            <VerifySensorButton
              className={`verify-sensor-button ${
                answers[matchedFunction.functionId] === 'normal' ? 'active' : ''
              }`}
              onClick={() => choooseAnswer(matchedSensor, 'normal', true)}
              value="normal"
            >
              Normal
            </VerifySensorButton>
            <VerifySensorButton
              className={`verify-sensor-button ${
                answers[matchedFunction.functionId] === 'high' ? 'active' : ''
              }`}
              onClick={() => choooseAnswer(matchedSensor, matchedFunction.functionId, 'high')}
              value="high"
            >
              High
            </VerifySensorButton>
            <VerifySensorButton
              className={`verify-sensor-button ${
                answers[matchedFunction.functionId] === 'clear' ? 'active' : ''
              }`}
              onClick={() => choooseAnswer(matchedSensor, matchedFunction.functionId, 'clear')}
              value="clear"
            >
              Clear
            </VerifySensorButton>
          </>
        )}
      </VerifySensorBoxAnswer>
    </>
  );
};

export default VerifyManualSensor;
