import React, { Children, cloneElement, useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import SelectedCauseTrendGraph from './SelectedCauseTrendGraph';
import { Icon } from '../../components/common/Icon';
import Tooltip from '../../components/common/Tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import RankingIndicator from '../../components/ranking/RankingIndicator';
import { useSelector } from 'react-redux';
import SelectedCauseRankHistory from './SelectedCauseRankHistory';
import { ReactComponent as RankUpIcon } from '../../svgs/rank-up-icon.svg';
import { ReactComponent as RankingIcon } from '../../svgs/ranking-icon.svg';

const StyledSelectedCause = styled(motion.div)`
  border-top: 1px solid #454545;
  border-bottom: ${(props) => (props.withCounteractions ? null : '1px solid #454545')};
  height: max-content;
  overflow: hidden;
  display: grid;
  padding: 0 var(--spacing-30);
  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
`;
const StyledRow = styled(motion.div)`
  padding: ${(props) => (props.showHistory ? '1.25rem 0' : '1.25rem 0 0 0')};
  display: grid;
`;
const StyledSelectedCauseGrid = styled(motion.div)`
  display: grid;
  grid-template-areas:
    'icon label        count'
    'icon description  count';
  cursor: pointer;
  grid-template-columns: max-content auto max-content;
  grid-auto-rows: max-content;
  grid-column-gap: 10px;
  padding: 0 0.8em;
`;
const StyledLabel = styled(motion.div)`
  font-size: var(--font-size-14);
  font-weight: 600;
  color: white;
  grid-area: label;
`;
const StyledDescription = styled(motion.div)`
  opacity: 0.6;
  font-size: var(--font-size-12);
  grid-area: description;
  &:after {
    content: '';
    display: inline-block;
  }
`;
const StyledSelectedCauseIcon = styled(motion.div)`
  background-color: var(--color-root-causes);
  color: black;
  border-radius: 50%;
  grid-area: icon;
  font-size: 14px;
  align-self: start;
  width: 24px;
  height: 24px;
`;

const StyledTrends = styled(motion.div)`
  height: max-content;
  display: grid;
  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
`;
const StateSvgName = {
  target: 'cra-target',
  hazard: 'cra-hazard',
  breach: 'cra-breach',
  high: 'cra-high',
  low: 'cra-low',
  normal: 'cra-neutral',
  sensor: 'cra-sensor',
  mcoHigh: 'cra-mco-high',
  mcoLow: 'cra-mco-low',
};

const StyledHeading = styled.h3`
  color: var(--color-gray-light);
  font-size: var(--font-size-14);
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
`;

const RankingHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 20px;
  padding: 0.8rem 0;
`;

const RankingTooltipIcon = styled(motion.div)`
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background-color: ${(props) => {
    if (props.color === 'orange') {
      return 'var(--color-observations)';
    }
    if (props.color === 'gray') {
      return 'var(--color-gray)';
    }
    if (props.color === 'darkest') {
      return 'var(--color-darkest)';
    }
  }};
`;

const RankingIndicatorTooltip = () => {
  return (
    <>
      <RankingTooltipIcon color="orange"></RankingTooltipIcon>
      <span>Supporting observations</span>
      <div />
      <RankingTooltipIcon color="gray"></RankingTooltipIcon>
      <span>Conflicting observations</span>
      <div />
      <RankingTooltipIcon color="darkest"></RankingTooltipIcon>
      <span>No observation received</span>
    </>
  );
};

const AnimatedInOut = ({ children, withKey, delay = 0 }) => {
  const elements = Children.toArray(children);
  return (
    <AnimatePresence>
      {elements.map((child) =>
        cloneElement(child, {
          key: withKey,
          initial: { y: '30px', opacity: 0, transition: { delay: 0.1 } },
          animate: { y: '0px', opacity: 1 },
          exit: { y: '-30px', opacity: 0 },
          transition: { type: 'tween', duration: 0.2, delay },
        })
      )}
    </AnimatePresence>
  );
};
const Selected = ({ selected }) => {
  const {
    label = '',
    description = '—',
    isSensor,
    limits,
    sensor,
    functionId,
    unit,
    state,
    relativeRank,
    concept,
  } = selected;
  const selectedRef = useRef(null);
  const [width] = useResizeObserver(selectedRef);
  const { COUNTERACTIONS, ENABLE_RANKING_HISTORY } = useSelector((state) => state.api.flags);
  const usingKey = (componentName) => `${componentName} ${label} ${state}`;
  const [showHistory, setShowHistory] = useState(false);

  const iconByConceptAndState =
    concept === 'mco' || concept === 'dco' || concept === 'sup'
      ? state === 'high'
        ? 'mcoHigh'
        : 'mcoLow'
      : state;

  return (
    <StyledSelectedCause layout ref={selectedRef} withCounteractions={COUNTERACTIONS}>
      <StyledRow layout="position" showHistory={showHistory || !ENABLE_RANKING_HISTORY}>
        <StyledSelectedCauseGrid>
          <StyledSelectedCauseIcon>
            {state && (
              <Icon
                name={StateSvgName[iconByConceptAndState]}
                height="24"
                width="24"
                fill="black"
              />
            )}
          </StyledSelectedCauseIcon>
          <StyledLabel>{label}</StyledLabel>
          <StyledDescription>{description}</StyledDescription>
          <Tooltip message={RankingIndicatorTooltip()} label={label}>
            <RankingIndicator rank={relativeRank} height="40" width="40" />
          </Tooltip>
        </StyledSelectedCauseGrid>

        {ENABLE_RANKING_HISTORY && (
          <>
            <RankingHeadingWrapper>
              <RankUpIcon />
              <StyledHeading>Ranking</StyledHeading>
              <button onClick={() => setShowHistory(!showHistory)}>
                <RankingIcon />
              </button>
            </RankingHeadingWrapper>
            <StyledTrends>
              <AnimatedInOut withKey={usingKey('trend')}>
                {showHistory && (
                  <SelectedCauseRankHistory width={width} height={100} functionId={functionId} />
                )}
              </AnimatedInOut>
            </StyledTrends>
          </>
        )}
        {/* Sensor readings trend graph commented out for now, WIP */}
        {/* {isSensor && (
          <StyledTrends>
            <AnimatedInOut withKey={usingKey('trend')}>
              <SelectedCauseTrendGraph
                limits={limits}
                sensor={sensor}
                functionId={functionId}
                unit={unit}
                width={width}
              />
            </AnimatedInOut>
          </StyledTrends>
        )} */}
      </StyledRow>
    </StyledSelectedCause>
  );
};

// showHistory = true for stying purposes
const BlankState = () => {
  return (
    <StyledSelectedCause>
      <StyledRow showHistory={true}>No cause selected</StyledRow>
    </StyledSelectedCause>
  );
};
const SelectedCause = {
  Selected,
  BlankState,
};
export default SelectedCause;
