import { useMemo } from 'react';
import { scaleTime } from '@visx/scale';
import { useResizeObserver } from './useResizeObserver';
import { useSelector } from 'react-redux';

const useTimescale = (now, ref, options = {}) => {
  const { TIMELINE_WINDOW } = useSelector((state) => state.api.flags);
  const { clamp = false, time = TIMELINE_WINDOW } = options;
  const [width, height = 0] = useResizeObserver(ref);

  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [0, width],
        domain: [now.minus(time), now],
        clamp,
      }),
    [width, now]
  );

  return [dateScale, width, height];
};

export default useTimescale;
