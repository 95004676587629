import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import ListHead from '../../components/List/ListHead';
import styled from '@emotion/styled/macro';
import {
  getManualVerifiedFromSelectedCause,
  getSelectedCause,
  getSensorsAndAnswers,
} from '../../store/ui/uiSelectors';
import { ParentSize } from '@visx/responsive';
import VerifyCauseItem from './VerifyCauseItem';
import { answerClick } from '../../store/verifyCause/verifyCauseActions';
import { VerifyCauseLines } from './VerifyCauseLines';
import { ReactComponent as ManualVerifyIcon } from '../../svgs/cra-manual-verify.svg';
import { ReactComponent as IconClearButton } from '../../svgs/icon-close.svg';
import { getModel } from '../../store/api/selectors/model';
import { postResetManualsMetrics } from '../../store/api';
import HelpScreenLabel from '../../components/common/HelpScreenLabel';

const Box = styled.div`
  --box-padding: 1.2rem;
  --box-border-radius: 10px;
  background-color: var(--color-dark-shade);
  border-radius: var(--box-border-radius);
  z-index: 10;
  align-self: center;
  width: 300px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: calc(100px + 50px);
`;

const Triangle = styled.div`
  position: absolute;
  top: -8px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 8px solid var(--color-dark);
`;

const Head = styled.div`
  background-color: var(--color-dark);
  padding: 0.66em var(--box-padding);
  border-top-right-radius: var(--box-border-radius);
  border-top-left-radius: var(--box-border-radius);
  grid-area: text;
  font-size: var(--font-size-16);
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr min-content;
  grid-template-areas: 'decorator text';
  grid-gap: var(--spacing-10);
  align-items: center;
`;

const List = styled.ul`
  padding: calc(var(--box-padding) / 2);
  list-style: none;
  display: grid;
  grid-gap: 0.1rem;
  margin: 0;
  font-size: var(--font-size-14);
  max-height: 240px;
  overflow-y: auto;
  width: 100%;
`;

const ListItem = styled.li`
  padding: calc(var(--box-padding) / 2);
  border-radius: var(--box-border-radius);
  width: 100%;
  &:hover {
    background-color: var(--color-dark);
  }
  overflow: hidden;
`;

const VerifyCauseBoxWrapper = styled.div`
  height: calc(100% - var(--top-bar) * 2);
`;

const StyledSvgAndContentWrapper = styled(ParentSize)`
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
`;

const StyledMagnifierButton = styled.button`
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: var(--color-dark);
  border-radius: 100%;
  top: 100px;
  color: var(--color-charcoal-light);
  text-align: center;
`;

const VerifyCauseHeading = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0rem;
`;

const ClearButton = styled.button`
  background-color: var(--color-gray-light);
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--color-charcoal);
  display: grid;
  grid-template-columns: 2rem min-content;
  align-items: center;
`;

const IconClear = styled(IconClearButton)`
  width: 0.8rem;
  height: 0.8rem;
  fill: var(--color-charcoal);
  cursor: pointer;
  & path {
    fill: var(--color-charcoal);
  }
`;

const choices = {
  low: { text: 'Low', value: 'low' },
  high: { text: 'High', value: 'high' },
  normal: { text: 'Normal', value: 'normal' },
  breach: { text: 'Breach', value: 'breach' },
  clear: { text: 'Clear', value: 'clear' },
};

const choicesByConcept = (concept) => {
  switch (concept) {
    case 'bar':
      return [choices.breach, choices.normal];
    default:
      // return [choices.low, choices.normal, choices.high, choices.clear];
      return [choices.low, choices.normal, choices.high];
  }
};

const VerifyCause = () => {
  const { instruments, verified } = useSelector(getManualVerifiedFromSelectedCause);
  const { COUNTERACTIONS } = useSelector((state) => state.api.flags);
  const model = useSelector(getModel);
  const dispatch = useDispatch();
  const { answers, sensors } = useSelector(getSensorsAndAnswers);
  const selected = useSelector(getSelectedCause);
  const sessionId = useSelector((state) => state.api.sessionId);

  const _instruments = useCallback(
    instruments.map((ins) => {
      const verifyState = answers[ins.functionId];
      //if we have optional label description in mfm model -> show optional label
      const labelFromMfm =
        model[ins.functionId] &&
        model[ins.functionId].labels &&
        model[ins.functionId].labels.inspection;

      const descriptionFromMfm = model[ins.functionId] && model[ins.functionId].description;
      return {
        ...ins,
        label: labelFromMfm ? labelFromMfm.description : ins.label,
        description: descriptionFromMfm ? descriptionFromMfm : '',
        verifyState: verifyState ? verifyState : null,
      };
    }),
    [instruments, answers, model]
  );

  const handleChoiceClick = (functionId, value) => {
    const matchSensor = sensors.find((s) => s.function.id === functionId);
    dispatch(
      answerClick({
        functionId,
        ttl: matchSensor.ttl || 30,
        answer: value,
        isNormal: value === 'normal' || value === 'clear' ? true : undefined,
      })
    );
  };

  const handleClearManuals = async (instruments) => {
    instruments.forEach((element) => {
      const { functionId } = element;
      if (element.status === 'ONGOING') {
        dispatch(answerClick({ ttl: 0, functionId, answer: 'clear', isNormal: true }));
      }
    });
    await postResetManualsMetrics(sessionId, { isConfirmed: true });
  };

  return (
    <AnimatePresence>
      <ListHead />
      <VerifyCauseBoxWrapper key="verify-cause-box-wrapper">
        <StyledSvgAndContentWrapper>
          {({ width, height }) => {
            return (
              <>
                {selected && (
                  <>
                    <VerifyCauseLines
                      width={width}
                      height={height}
                      verifiable={Boolean(instruments.length)}
                      withCounteractions={COUNTERACTIONS}
                    />
                    {COUNTERACTIONS && (
                      <StyledMagnifierButton>
                        <ManualVerifyIcon
                          fill={
                            instruments.length
                              ? 'var(--color-observations)'
                              : 'var(--color-charcoal-light)'
                          }
                          stroke={
                            instruments.length
                              ? 'var(--color-observations)'
                              : 'var(--color-charcoal-light)'
                          }
                          strokeWidth="0"
                          width="0.8rem"
                          height="0.8rem"
                        />
                      </StyledMagnifierButton>
                    )}
                  </>
                )}
                {Boolean(instruments.length) && COUNTERACTIONS && (
                  <Box key="box">
                    <Triangle />
                    <Head>
                      <Row>
                        <ManualVerifyIcon
                          fill="var(--color-observations)"
                          stroke="var(--color-observations)"
                          strokeWidth="0"
                          width="1rem"
                          height="1rem"
                        />
                        <VerifyCauseHeading>Verify cause</VerifyCauseHeading>
                        <ClearButton
                          onClick={() => {
                            handleClearManuals(instruments);
                          }}
                        >
                          Clear <IconClear />
                        </ClearButton>
                      </Row>
                    </Head>
                    <List>
                      {_instruments.map((instrument) => (
                        <ListItem key={`${instrument.label}-${instrument.functionId}`}>
                          <VerifyCauseItem
                            instrument={instrument}
                            verified={verified}
                            choices={choicesByConcept(instrument.concept)}
                            concept={instrument.concept}
                            onChoiceClick={handleChoiceClick}
                            verifyState={instrument.verifyState}
                          ></VerifyCauseItem>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </>
            );
          }}
        </StyledSvgAndContentWrapper>
      </VerifyCauseBoxWrapper>
    </AnimatePresence>
  );
};

export default VerifyCause;
