import styled from '@emotion/styled/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconPrevention } from '../../svgs/user.svg';
import Tooltip from '../../components/common/Tooltip';
import { useKeycloak } from 'keycloak-react-web';

const StyledButton = styled.button`
  width: 2rem;
  height: 2rem;
  /* position: absolute; */
  right: 30px;
  top: 15px;
  padding-left: 10px;
`;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & span {
    cursor: pointer;
  }
`;

export const SignOutButton = () => {
  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();
  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <StyledButton onClick={() => handleLogout()}>
      <Tooltip
        message={
          <StyledMessage>
            {/* <span>Username</span> */}
            <span>Logout</span>
          </StyledMessage>
        }
      >
        <IconPrevention fill="none" width="1.5rem" height="1.5rem" />
      </Tooltip>
    </StyledButton>
  );
};
