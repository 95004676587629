import React, { Children, cloneElement, useEffect, useRef } from 'react';
import { AnimatePresence, motion, useScroll } from 'framer-motion';
import { useSelector } from 'react-redux';
import { modeIsRewind } from '../../store/mode/selectors';

const transition = (index) => ({ delay: (index + 0.1) * 0.02, duration: 0.2, type: 'tween' });
const motionChild = {
  visible: ({ index }) => ({
    opacity: 1,
    y: 0,
    transition: transition(index),
    zIndex: 1,
  }),
  hidden: ({ index }) => ({
    opacity: 0,
    y: '10px',
    transition: transition(index),
  }),
  exit: ({ index }) => ({
    opacity: 0,
    transition: transition(index),
  }),
};

const AnimateTimelineList = ({ children, className, isVisible = true }) => {
  const isRewind = useSelector(modeIsRewind);
  const uiObservationClicked = useSelector((state) => state.ui.uiObseravationClicked);
  let elements = Children.toArray(children);
  const ref = useRef();
  useEffect(() => {
    if (uiObservationClicked) {
      const timelineItem = ref.current.querySelector(`[data-id="${uiObservationClicked}"]`);
      timelineItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [uiObservationClicked]);
  return (
    <AnimatePresence initial={false}>
      <motion.div className={className} ref={ref}>
        {isVisible &&
          elements.map((child, index) => {
            return cloneElement(child, {
              layout: 'true',
              variants: motionChild,
              key: child.key,
              exit: 'exit',
              initial: 'hidden',
              animate: 'visible',
              custom: { index },
              // eslint-disable-next-line no-restricted-globals
              length,
            });
          })}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimateTimelineList;
