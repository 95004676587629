import React, { useRef, useMemo } from 'react';
import { Group } from '@visx/group';
import { Brush } from '@visx/brush';
import { Line } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { useTooltip } from '@visx/tooltip';
import TrendTooltip from '../../components/trend/TrendTooltip';

import { timeFormat } from 'd3-time-format';

import TrendGrid from '../../components/trend/TrendGrid';

const formatDate = timeFormat('%H:%M');
const formatDay = timeFormat('%m/%d');

const getStartedDate = (d) => new Date(d.started);
const getLastedDate = (d) => new Date(d.lasted);

const selectedBrushStyle = {
  fill: 'rgba(245, 245, 245, 0.2)',
  stroke: 'white',
  strokeWidth: '0px',
};

const lineHeight = 2;
const marginBetweenLines = 2;
const spaceBetweenBarsAndTicks = 5;
const ticksSize = 12;
const heightOfTimeFont = 15;

const ScrubberBrush = ({
  // time: { aboutNow, brushInitialStart },
  brushStart,
  brushEnd,
  dateScale: brushDateScale,
  timeline = [],
  handleBrushChange,
  width,
  margin = {
    top: 8,
    left: 0,
    bottom: 8,
    right: 0,
  },
}) => {
  // const { showTooltip, hideTooltip, tooltipData, tooltipTop, tooltipLeft, tooltipOpen } =
  //   useTooltip();
  const brushRef = useRef(null);
  const linesHeight = timeline.length * (lineHeight + marginBetweenLines);
  const barsHeight = linesHeight + margin.top + margin.bottom;
  const totalHeight = barsHeight + ticksSize + spaceBetweenBarsAndTicks;

  const xBrushMax = Math.max(width, 0);
  const yBrushMax = Math.max(barsHeight, 0);

  const brushObservationsScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [0, timeline.length || 0],
        nice: true,
      }),
    [yBrushMax, timeline]
  );

  const initialBrushPosition = {
    start: { x: brushDateScale(brushStart) },
    end: { x: brushDateScale(brushEnd) },
  };

  function onBrushChange(domain) {
    if (!domain) return;

    const { x0, x1 } = domain;
    const start = new Date(x0);
    const end = new Date(x1);

    handleBrushChange({ start, end });
  }

  const dateKey = Date.now(brushEnd);
  return (
    <>
      {width && (
        <svg width={width} height={totalHeight}>
          <Group left={margin.left} top={margin.top}>
            <Group top={0}>
              {timeline &&
                timeline.map(({ observations, id }, index) => (
                  <Group key={id + index} top={lineHeight}>
                    {/* <Group key={id + index} top={index * (lineHeight + marginBetweenLines)}> */}
                    {observations.map((o) => (
                      <Line
                        stroke="var(--color-observations)"
                        strokeWidth={lineHeight + 'px'}
                        strokeLinecap="round"
                        key={o.id + getStartedDate(o) + getLastedDate(o)}
                        from={{
                          x: brushDateScale(getStartedDate(o)),
                          y: index * (lineHeight + marginBetweenLines),
                        }}
                        to={{
                          x: brushDateScale(getLastedDate(o)),
                          y: index * (lineHeight + marginBetweenLines),
                        }}
                      />
                    ))}
                  </Group>
                ))}
            </Group>
          </Group>
          <TrendGrid
            width={width}
            height={barsHeight}
            scale={brushDateScale}
            formatFunction={formatDate}
            fontSize={ticksSize}
            offsetBy={spaceBetweenBarsAndTicks}
          />

          <TrendGrid
            width={width}
            height={barsHeight}
            scale={brushDateScale}
            formatFunction={formatDay}
            type={'date'}
            fontSize={ticksSize}
            offsetBy={spaceBetweenBarsAndTicks + heightOfTimeFont}
          />

          <Brush
            key={dateKey}
            /* brushRegion="xAxis" */
            xScale={brushDateScale}
            yScale={brushObservationsScale}
            width={xBrushMax}
            height={totalHeight}
            innerRef={brushRef}
            handleSize={0}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            brushDirection="horizontal"
            initialBrushPosition={initialBrushPosition}
            onBrushEnd={onBrushChange}
            selectedBoxStyle={selectedBrushStyle}
            useWindowMoveEvents
          />
        </svg>
      )}
      {/* <TrendTooltip data={tooltipData} top={10} left={tooltipLeft} limits={limits} unit={unit} /> */}
    </>
  );
};

export default ScrubberBrush;
