import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCauses,
  getConsequences,
  getConsequencesFilteredByCause,
} from '../../store/graph/graphSelectors';
import {
  getSelectedCause,
  getSelectedConsequence,
  getSelectedUuid,
  getPreventionsForSelectedConsequence,
} from '../../store/ui/uiSelectors';
import { consequenceClick, selectPreventions, toggleHelpPage } from '../../store/ui/uiActions';
import ListHead from '../../components/List/ListHead';
import { AnimateSharedLayout, motion } from 'framer-motion';
import ConsequencesList from './ConsequencesList';
import ConsequenceCard from './ConsequenceCard';
import { SignOutButton } from '../../components/SignOutButton';
import EquipmentPanelButton from '../../components/buttons/EquipmentPanelButton';
import OpenHelpPageButton from '../../components/buttons/OpenHelpPageButton';
import { Heading } from '../../components/Text/Heading';
import { getModel } from '../../store/api/selectors/model';
import { getPreventions } from '../../utils';
import { postCauseOrConsequenceClickedMetrics } from '../../store/api';
import { OPCSignal } from '../../components/OPCSignal';

const ConsequencesContainer = styled.div`
  display: grid;
  grid-template-rows: max-content min-content 1fr;
  height: 100%;
  overflow: hidden;
`;

const Head = styled.div`
  background-color: var(--color-dark-shade);
  padding: 1rem 1rem 1rem 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: var(--font-size-16);
  margin: 2rem 1rem 0 0;
`;

const StyledEmptyConsequencesBox = styled(motion.div)`
  background-color: var(--color-dark-shade);
  height: 3.5rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: right;
`;

const Consequences = () => {
  const dispatch = useDispatch();
  const consequences = useSelector(getConsequences);
  const selectedConsequence = useSelector(getSelectedConsequence);
  const selected = useSelector(getSelectedCause);
  const uuid = useSelector(getSelectedUuid);
  const authState = useSelector((state) => state.api.flags.ENABLE_AUTH);
  const causes = useSelector(getCauses);
  const sessionId = useSelector((state) => state.api.sessionId);
  const helpPageOpen = useSelector((state) => state.ui.uiHelpPageOpen);
 
  // const filteredByCauses = consequences.filter(({ causes }) => {
  //   return causes.some((cause) => cause === uuid);
  // });

  const filteredByCauses = useSelector(getConsequencesFilteredByCause);

  //THIS CLICKS ON A CONSEQUENCE MANUALLY WHEN A CAUSE IS SELECTED. MAYBE WE CAN IMPROVE THIS IN THE FUTURE.
  useEffect(() => {
    const weHaveSelectedACause = uuid ? true : false;
    if (weHaveSelectedACause) {
      let sortedConsequences = consequences
        .filter(({ causes }) => causes.some((cause) => cause === uuid))
        .sort((a, b) => a.rank + b.rank);

      if (sortedConsequences.length === 0) return;

      const [withObservations, withoutObservations] = _.partition(
        sortedConsequences,
        (obj) => obj.observationFunctions && obj.observationFunctions.length > 0
      );

      sortedConsequences = [...withObservations, ...withoutObservations];

      const foundConsequence = consequences.find((c) => c.uuid === sortedConsequences[0].uuid);
      dispatch(consequenceClick({ uuid: sortedConsequences[0].uuid }));
      const postMetricsAsyncData = async () => {
        await postCauseOrConsequenceClickedMetrics({
          session_id: sessionId,
          id: foundConsequence.functionId,
          rank: foundConsequence.rank,
          state: foundConsequence.state,
          action_name: 'CONSEQUENCE_CLICKED',
          reason: 'AUTO',
        });
      };
      postMetricsAsyncData();
    }
  }, [uuid]);

  const handleConsClick = (uuid, prevention) => {
    if (uuid === selectedConsequence) {
      dispatch(consequenceClick({ uuid: null }));
      dispatch(selectPreventions([]));
    } else {
      const foundConsequence = consequences.find((c) => c.uuid === uuid);
      dispatch(consequenceClick({ uuid: uuid }));
      const postMetricsAsyncData = async () => {
        await postCauseOrConsequenceClickedMetrics({
          session_id: sessionId,
          id: foundConsequence.functionId,
          rank: foundConsequence.rank,
          state: foundConsequence.state,
          action_name: 'CONSEQUENCE_CLICKED',
          reason: 'MANUAL',
        });
      };
      postMetricsAsyncData();
    }
  };

  const toggleHelp = () => {
    dispatch(toggleHelpPage(!helpPageOpen));
  };

  return (
    <ConsequencesContainer>
      <ListHead>
        <ButtonContainer>
          <OPCSignal />
          <EquipmentPanelButton />
          <OpenHelpPageButton buttonAction={toggleHelp} />
          <SignOutButton />
        </ButtonContainer>
      </ListHead>

      <AnimateSharedLayout>
        {causes.length !== 0 && consequences.length !== 0 && (
          <Head>
              { !helpPageOpen ?
                <Heading>Consequences</Heading> :
                <Heading>&nbsp;</Heading>
              }
          </Head>
        )}
        <ConsequencesList listName="consequence">
          {filteredByCauses.map((item, idx) => {
            const isSelected = item.uuid === selectedConsequence;
            return (
              <ConsequenceCard
                type={'consequence'}
                key={item.uuid}
                data={item}
                label={item.label}
                id={item.uuid}
                onCardClick={() => handleConsClick(item.uuid, item.preventive)}
                labels={item.state}
                isSelected={isSelected}
                {...item}
              />
            );
          })}
          {filteredByCauses.length === 0 && selected && (
            <StyledEmptyConsequencesBox>No consequences</StyledEmptyConsequencesBox>
          )}
        </ConsequencesList>
      </AnimateSharedLayout>
    </ConsequencesContainer>
  );
};

export default Consequences;
