import React from 'react';

export const GreenCheckMark = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47059 2.35295L3.82353 4.7059L8.52941 0L10 1.4706L3.82353 7.64709L0 3.82355L1.47059 2.35295Z"
        fill="#50E3C2"
      />
    </svg>
  );
};
