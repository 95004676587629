import React from 'react';

const TrendLoading = ({ width, height }) => {
  return (
    <g transform="translate(-25, -25)">
      <svg
        x={width / 2}
        y={height / 2}
        width="50px"
        height="50px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="currentColor"
          strokeWidth="5"
          r="35"
          strokeDasharray="164 56"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </circle>
      </svg>
    </g>
  );
};

export default TrendLoading;
