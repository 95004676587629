import React, { useMemo, useState, useEffect } from 'react';
import { coerceNumber } from '@visx/scale';
import { timeFormat } from 'd3-time-format';
import Tick from '../timeline/Ticks/Tick';
import { utcMinute } from 'd3-time';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { modeIsRewind } from '../../store/mode/selectors';

const formatDate = timeFormat('%H:%M');

const createTickObject = (scale) => {
  return function (d) {
    return {
      id: d,
      text: formatDate(d),
      x: coerceNumber(scale(d)) || 0,
    };
  };
};

const TimelineAxis = ({ timelineScale, now }) => {
  const [timestamp, setTimestamp] = useState(() => now);
  const isRewind = useSelector(modeIsRewind);
  const { TIMELINE_REWIND, TIMELINE_WINDOW } = useSelector((state) => state.api.flags);
  const time = isRewind ? TIMELINE_REWIND : TIMELINE_WINDOW;

  useEffect(() => {
    if (!isRewind) {
      setTimestamp(now);
    }
  }, [now, isRewind]);

  const d3Ticks = useMemo(() => {
    return utcMinute.every(5).range(timestamp.minus(time), timestamp);
  }, [time, timestamp]);

  const ticks = useMemo(() => {
    const tick = createTickObject(timelineScale);
    return d3Ticks.map(tick);
  }, [timelineScale]);

  return (
    <AnimatePresence>
      {ticks.map(({ x, id, text }) => (
        <Tick key={id} id={id} x={x} text={text} />
      ))}
    </AnimatePresence>
  );
};

export default TimelineAxis;
