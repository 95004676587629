import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './containers/App';
import { KeycloakProvider } from 'keycloak-react-web';
import Keycloak from 'keycloak-js';

const root = ReactDOMClient.createRoot(document.getElementById('root'));

const isLocalhost =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const isInContainer = window.location.port === '';

const keycloakUri = !isLocalhost
  ? `${window.location.protocol}//${window.location.hostname}/keycloak/`
  : isInContainer
  ? 'http://localhost/keycloak/'
  : 'http://localhost/keycloak/';

const settings = {
  url: keycloakUri,
  realm: 'cra',
  clientId: 'cra',
};
export const authInstance = new Keycloak(settings);

root.render(
  <KeycloakProvider client={authInstance}>
    <App />
  </KeycloakProvider>
);
