import styled from '@emotion/styled/macro';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import client from '../../store/connect';
import Tooltip from '../common/Tooltip';

const StyledOPCIndicator = styled.div`
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: ${({ isFresh }) => (isFresh ? 'rgb(29 212 77)' : 'var(--color-red)')};
`;
const StyledOPCSignalWrapper = styled.button`
  display: flex;
  align-items: center;
  color: var(--color-gray-light);
  font-size: var(--font-size-14);
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  z-index: 99999;
`;
const StyledOPCLabel = styled.span`
  text-transform: none;
  font-weight: normal;
  font-size: 14px;
`;

const StyledTooltipSpan = styled.span`
  left: 15;
`;

export const OPCSignal = () => {
  const [opcSignalTimeStamp, setOpcSignalTimeStamp] = useState(null);
  const [opcSignalStatus, setOPCSignalStatus] = useState('fresh'); // stale, fresh
  const { OPC_SIGNAL_TIMEWINDOW = 15, ROUTING_KEY } = useSelector((state) => state.api.flags);

  // Store the timeout ID to clear it later
  const timeoutIdRef = useRef();

  const throttle = (func, limit) => {
    let inThrottle;
    return function () {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  const delayedOpcStateFlip = () => {
    // Only set a new timeout if the status is fresh
    if (opcSignalStatus === 'fresh') {
      // Clear any existing timeout to avoid multiple timeouts
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      let timeLimit = OPC_SIGNAL_TIMEWINDOW * 1000;

      // Set a new timeout
      timeoutIdRef.current = setTimeout(() => {
        setOPCSignalStatus('stale');
      }, timeLimit);
    }
  };

  const handleSignal = async () => {
    setOpcSignalTimeStamp(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS));
    if (opcSignalStatus === 'stale') {
      setOPCSignalStatus('fresh');
    }
    delayedOpcStateFlip();
  };

  const throttledHandleSignal = throttle(handleSignal, 4000);

  useEffect(() => {
    client.on(ROUTING_KEY, handleSignal);

    // Initiate the timeout when the component mounts
    delayedOpcStateFlip();

    return () => {
      client.off(ROUTING_KEY, throttledHandleSignal);
      // Clear the timeout when the component unmounts
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [opcSignalStatus]);

  const TooltipMessage = () => {
    if (opcSignalStatus === 'fresh') {
      return (
        <>
          <StyledTooltipSpan>OPC data received at {opcSignalTimeStamp}</StyledTooltipSpan>
        </>
      );
    } else {
      if (opcSignalTimeStamp !== null){
        return (
          <>
            <StyledTooltipSpan>OPC data has not been received since {opcSignalTimeStamp}</StyledTooltipSpan>
          </>
        );
      } else {
        return (
          <>
            <StyledTooltipSpan>Waiting for incoming OPC data</StyledTooltipSpan>
          </>
        ); 
      }
      
    }
  };

  return (
    <Tooltip message={TooltipMessage()}>
      <StyledOPCSignalWrapper>
        <StyledOPCLabel>OPC Status: </StyledOPCLabel>
        <StyledOPCIndicator isFresh={opcSignalStatus === 'fresh' ? true : false} />
      </StyledOPCSignalWrapper>
    </Tooltip>
  );
};
