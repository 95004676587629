import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as IconUncommitted } from '../../svgs/cra-uncommitted.svg';
import { ReactComponent as IconCommitted } from '../../svgs/cra-commited-manual.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { postVerifyCauseMetrics } from '../../store/api';

const ChoiceButton = styled.button`
  padding: ${(props) => {
    if (props.concept === 'bar') {
      return '8px 41px';
    } else {
      return '8px 25px';
    }
  }};
  border: solid 1px var(--color-charcoal-light, currentColor);
  font-size: 0.8rem;
  font-family: 'Roboto';
  background-color: var(--selected-bg-color, transparent);
  color: var(--selected-color, inherit);
  margin: 0px 0;
  &:first-of-type {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  &:last-of-type {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
`;

const ChoiceButtonSelected = styled.button`
  --selected-bg-color: ${(props) => {
    if (props.selected && props.value === 'normal') {
      return 'var(--color-gray)';
    } else if (props.selected) {
      return 'var(--color-observations)';
    } else {
      return 'transparent';
    }
  }};
  --selected-color: ${(props) => props.selected && 'black'};
`;

const ChoicesWrapper = styled(motion.div)`
  padding-top: 0.8rem;
  overflow: hidden;
  width: 100%;
`;

const Icon = styled.div`
  color: var(--color-observations);
  margin-right: 0.6rem;
`;

const TextAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChoicesAnimatedWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 25px;
`;

const VerifyCauseLabelAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
`;

const VerifyCauseItem = (props) => {
  const {
    instrument,
    verified,
    isExpanded = false,
    choices = [],
    onChoiceClick,
    verifyState,
  } = props;

  const [expanded, setExpanded] = useState(() => isExpanded);
  const [selected, setSelected] = useState(null);
  const sessionId = useSelector((state) => state.api.sessionId);

  useEffect(() => {
    setSelected(verifyState);
  }, [verifyState]);

  const handleClick = async ({ target }) => {
    const { value } = target;
    if (instrument.functionId) setSelected(value);
    onChoiceClick(instrument.functionId, value);
    await postVerifyCauseMetrics(sessionId, 'VERIFY_CAUSE_ENTERED');
  };

  const handleExpandClick = async () => {
    await postVerifyCauseMetrics(sessionId, 'VERIFY_CAUSE_EXPANDED');
    setExpanded(!expanded);
  };

  return (
    <ItemWrapper>
      <TextAndIconWrapper onClick={() => handleExpandClick()}>
        <Icon>
          {verified.some((verify) => instrument.functionId === verify.functionId) ? (
            <IconCommitted
              fill={instrument.verifyState === 'normal' ? 'var(--color-gray)' : 'currentColor'}
              stroke="currentColor"
              strokeWidth="0"
              width="1rem"
              height="1rem"
            />
          ) : (
            <IconUncommitted
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              width="1rem"
              height="1rem"
            />
          )}
        </Icon>
        <VerifyCauseLabelAndDescription>
          <span className="text-ellipsis">{instrument.label}</span>
          <span className="text-small color-gray text-ellipsis">{instrument.description}</span>
        </VerifyCauseLabelAndDescription>
      </TextAndIconWrapper>
      <AnimatePresence>
        {expanded && (
          <ChoicesWrapper
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ type: 'tween' }}
          >
            <ChoicesAnimatedWrapper>
              {choices.map((ch) => {
                return (
                  <ChoiceButton
                    as={ChoiceButtonSelected}
                    concept={instrument.concept}
                    value={ch.value}
                    key={ch.value}
                    onClick={handleClick}
                    selected={selected === ch.value}
                  >
                    {ch.text}
                  </ChoiceButton>
                );
              })}
            </ChoicesAnimatedWrapper>
          </ChoicesWrapper>
        )}
      </AnimatePresence>
    </ItemWrapper>
  );
};

export default VerifyCauseItem;
