import { handleActions } from 'redux-actions';

import {
  receivedTimeline,
  receivedModel,
  receivedGraphState,
  receivedEquipment,
  receivedFlags,
  receivedEquipmentConfig,
  setCmrsStatus,
  receivedSessionId,
} from './actions';

const defaultState = {
  observations: [],
  causes: [],
  consequences: [],
  models: {},
  flags: {},
  equipment: {
    config: {},
    events: [],
  },
  cmrsStatus: {},
  sessionId: null,
};

export default handleActions(
  {
    [receivedTimeline]: (state, action) => {
      return {
        ...state,
        observations: action.payload,
      };
    },
    [receivedSessionId]: (state, action) => {
      return {
        ...state,
        sessionId: action.payload,
      };
    },
    [receivedGraphState]: (state, { payload }) => {
      const { causes = [], consequences = [] } = payload;
      return {
        ...state,
        causes,
        consequences,
      };
    },
    [receivedModel]: (state, action) => {
      return {
        ...state,
        models: action.payload,
      };
    },
    [receivedFlags]: (state, action) => ({
      ...state,
      flags: { ...action.payload },
    }),
    [receivedEquipment]: (state, action) => {
      const events = action.payload;
      return {
        ...state,
        equipment: {
          ...state.equipment,
          events,
        },
      };
    },
    [receivedEquipmentConfig]: (state, action) => {
      const config = action.payload;
      return {
        ...state,
        equipment: {
          ...state.equipment,
          config,
        },
      };
    },
    [setCmrsStatus]: (state, action) => {
      const { reasoning } = action.payload;
      return {
        ...state,
        cmrsStatus: reasoning,
      };
    },
  },

  defaultState
);
