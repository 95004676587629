/* TODO: Deprecate this file in favor of useTimescale in /hooks */

import { useMemo } from 'react';
import { scaleTime } from '@visx/scale';
import { useSelector } from 'react-redux';
import { getTimestamp } from '../../store/ui/uiSelectors';

const useTimeScale = (xMax) => {
  const timestamp = useSelector(getTimestamp);

  const timeScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: [
          new Date(timestamp).setMinutes(new Date(timestamp).getMinutes() - 30),
          new Date(timestamp).valueOf(),
        ],
        clamp: false,
      }),
    [xMax, timestamp]
  );
  return timeScale;
};

export default useTimeScale;
