import { createAction } from 'redux-actions';
import { normalizeModel, getCausesAndConsequences, normalizeEquipmentConfig } from './utils';

export const getLiveData = createAction('api/GET_LIVE_DATA');
export const doBootSequence = createAction('api/BOOT_SEQUENCES_STARTED');

export const getMostRecent = createAction('api/GETTING_MOST_RECENT');
export const gotMostRecent = createAction('api/GOT_MOST_RECENT_DATA');

export const gotApiError = createAction('api/ERROR');
export const receivedTimeline = createAction('api/RECEIVED_TIMELINE');
export const receivedModel = createAction('api/RECEIVED_MODEL', normalizeModel);
export const receivedGraphState = createAction('api/RECEIVED_GRAPH', getCausesAndConsequences);
export const receivedEquipment = createAction('api/RECEIVED_EQUIPMENT');
export const receivedEquipmentConfig = createAction(
  'api/RECEIVED_EQUIPMENT_CONFIG',
  normalizeEquipmentConfig
);
export const receivedEquipmentUpdate = createAction('api/RECEIVED_EQUIPMENT_UPDATE');
export const receivedFlags = createAction('api/RECEIVED_FLAGS');
export const receivedGraphStateUpdate = createAction('api/RECEIVED_GRAPH_STATE_UPDATE');
export const receivedCmrsStatusUpdate = createAction('api/RECEIVED_CMRS_STATUS_UPDATE');
export const setCmrsStatus = createAction('api/SET_CMRS_STATUS');
export const receivedSessionId = createAction('api/RECEIVED_SESSION_ID');
export const receivedERStateUpdate = createAction('api/RECEIVED_ER_STATE_UPDATE');
