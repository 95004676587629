import _, { orderBy, size, partition, filter } from 'lodash';
import { createSelector } from 'reselect';
import { GRAPH_STATE_LABELS } from '../../constants';
import { getTimeline } from '../timeline/timelineSelectors';
import { getModel } from '../api/selectors/model';
import { getSelectedUuid } from '../ui/uiSelectors';

export const getStateCauses = (state) => state.api.causes;
export const getStateConsequences = (state) => state.api.consequences;
export const getSessionId = (state) => state.api.sessionId;
const getSelectedIds = (state) => state.ui.uiSelectedObservations;
const modelSelector = (state) => state.api.models;
const getSelectedUuidGraph = (state) => state.ui.uiSelectedCause;
/**
 * A Graph State Object
 * @typedef {Object} GraphStateObject
 * @property {string} state
 * @property {string} uuid
 * @property {string} functionId
 * @property {number} rank
 * @property {string[]} observationFunctions
 * @property {string[]} instrumentationFunctions
 */

/**
 * The information extracted from the model
 * @typedef {Object} Limit
 * @property {string} severity
 * @property {number} limit
 */

/**
 * The information extracted from the model
 * @typedef {Object} ModelInformation
 * @property {string} [label]
 * @property {Limit[]} [limits]
 * @property {string} [sensor]
 * @property {string} [description]
 * @property {Boolean} isSensor
 */

/**
 * Helper function to just grab the relevant information from the model-file.
 * @param {Object} modelObject
 * @returns {ModelInformation}
 */
function extractFromModel(modelObject) {
  const {
    label = 'No label',
    limits,
    sensor,
    description,
    concept,
    objectRole,
    category,
  } = modelObject;
  return {
    label,
    limits,
    sensor,
    description,
    isSensor: Boolean(limits),
    concept,
    objectRole,
    category: category || 'other',
  };
}

/**
 * The information extracted from the model
 * @typedef {Object} MergedGraphStateAndModel
 * @property {ModelInformation}
 * @property {GraphStateObject}
 */

/**
 * Merge graph state object with model
 * @param {GraphStateObject[]} arr - Array of causes or consequences
 * @param {Object} model - The entire model (of /api/model)
 * @returns {MergedGraphStateAndModel[]} - The merged objects
 */

/**
 * All causes merged with model
 * @returns {MergedGraphStateAndModel[]} - The merged objects
 */
export const getCauses = createSelector([getStateCauses, modelSelector], (causes, model) => {
  if (Object.keys(model).length === 0) {
    return [];
  }

  const mergedArray = causes.map((entry) => {
    const { state } = entry;
    const item = {
      ...extractFromModel(model[entry.functionId] || {}),
      ...entry,
      state: GRAPH_STATE_LABELS[state], // This converts "true" or "false" into
      preventive: [],
    };
    return item;
  });
  return orderBy(mergedArray, [(o) => o.rank], ['desc']);
});

export const getConsequences = createSelector(
  [getStateConsequences, modelSelector],
  (consequences, model) => {
    if (Object.keys(model).length === 0) {
      return [];
    }

    const mergedArray = consequences.map((entry) => {
      const { state } = entry;
      const item = {
        ...extractFromModel(model[entry.functionId] || {}),
        ...entry,
        state: state, // This converts "true" or "false" into
        preventive: [],
      };
      return item;
    });

    //WE ARE SORTING FIRST BY IF THERE ARE OBSERVATION FUNCTIONS, THEN BY RANKING
    const categories = ['safety', 'emissions', 'production', 'other'];
    const orderdedCons = orderBy(
      mergedArray,
      [(o) => categories.indexOf(o.category), (o) => o.rank],
      ['asc', 'desc']
    );
    return orderdedCons;
  }
);

export const getConsequencesFilteredByCause = createSelector(
  [getConsequences, getSelectedUuidGraph],
  (consequences, uuid) => {
    if (!uuid) {
      return [];
    }
    let filteredConsequences = consequences.filter(({ causes }) => {
      return causes.some((cause) => cause === uuid);
    });

    const [withObservations, withoutObservations] = partition(
      filteredConsequences,
      (obj) => obj.observationFunctions && obj.observationFunctions.length > 0
    );

    const sortedConsequences = [...withObservations, ...withoutObservations];

    return sortedConsequences;
  }
);

export const getSelectedObservations = createSelector(
  [getTimeline, getSelectedIds],
  (timeline, selected) => {
    return timeline.filter((t) => selected.find((s) => t.functionId === s));
  }
);

export const getSelectedObservationsFunctionIds = createSelector(
  [getSelectedObservations],
  (observations) => {
    return observations.map((o) => o.functionId);
  }
);

export const getCorrectiveActions = createSelector([getModel], (model) => {
  const corrective = model[1]?.counteractions?.correct ? model[1]?.counteractions?.correct : {};
  return corrective;
});

export const getPreventions = createSelector([getModel], (model) => {
  const prevents = model[1]?.counteractions?.prevent ? model[1]?.counteractions?.prevent : {};

  return prevents;
});

export const getVerifySteps = createSelector([getModel], (model) => {
  const verifySteps = model[1]?.counteractions?.verify ? model[1]?.counteractions?.verify : {};
  return verifySteps;
});
