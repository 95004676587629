import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';

const apiPath = process.env.REACT_APP_API_DEV === 'true' ? ':3010' : '/api';
const isRunningInDev = Number.isInteger(parseInt(window.location.port)) ? true : false;
const client = io(window.location.hostname, { 
  path: `${apiPath}/socket.io`,
  withCredentials: false,
  extraHeaders: isRunningInDev ? {} : {
   "X-Frame-Options" :"SAMEORIGIN",
   "Content-Security-Policy" : "frame-ancestors 'none'",
   "Cache-Control": "no-cache, no-store"
  }
});

export function logTime(...args) {
  const opt = { month: 'numeric', day: 'numeric' };
  const dateStr = new Date().toLocaleTimeString('en-GB', opt);
  return console.log(
    `%c${dateStr}`,
    'background: #40424e; color: #f6b03a; font-weight: bold; padding: 5px 10px; border-radius:5px;',
    ...args
  );
}

export function subscribeToTopic(topic) {
  return eventChannel((emit) => {
    const topicHandler = () => {
      emit({ message: null });
    };

    const topicUnsubscribe = () => {
      client.off(topic, topicHandler);
    };

    const errorHandler = (errorEvent) => {
      emit(new Error(errorEvent.reason));
    };

    client.on('error', errorHandler);

    //subscribes to topic from api...
    client.on(topic, topicHandler);

    //... requests that api subscribes to topic from rbmq
    client.emit('subscribe', topic);
    logTime(`Listening to topic: ${topic}`);

    return topicUnsubscribe;
  });
}
const clientConnected = () => {
  logTime(`Connected to API via WS`);
};

// client.on('connect', () => {
//   logTime(`Connected to WS (${window.location.host}/api)`);
// });
client.on('connect', clientConnected);
client.on('reconnect', () => logTime(`WS reconnecting`));
client.on('disconnect', () => logTime(`WS disconnected`));
client.on('error', (error) => logTime('Webscoket error', error));

export default client;
