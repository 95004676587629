import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageVisibility from 'react-page-visibility';

import Store from './Store';
import Layout from './Layout';
import Abnormals from '../features/ObservationHistory/Abnormals';
import './App.css';
import { Redirect } from '../features/Redirect';
import { Login } from '../features/Login';
import { ProtectedRoute } from '../features/ProtectedRoute/iindex';

export default function App() {
  return (
    <BrowserRouter>
      <PageVisibility>
        {(isVisible) => {
          return (
            <Store>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/redirect" element={<Redirect />} />
                <Route path="/" element={<ProtectedRoute element={Layout} />} />
                <Route path="/observation-history" element={<Abnormals />} />
              </Routes>
            </Store>
          );
        }}
      </PageVisibility>
    </BrowserRouter>
  );
}
