import React from 'react';
import styled from '@emotion/styled/macro';
import Tooltip from '../common/Tooltip';
import { ReactComponent as HelpIcon } from '../../svgs/info.svg';

const StyledButton = styled.button`
  width: 2.2rem;
  height: 2rem;
  right: 25px;
  top: 15px;
  padding-left: 10px;
`;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & span {
    cursor: pointer;
  }
`;

export const OpenHelpPageButton = ({buttonAction}) => {
  return (
    <StyledButton
      onClick={() => {
        buttonAction();
      }}
    >
      <Tooltip
        message={
          <StyledMessage>
            <span>Help Page</span>
          </StyledMessage>
        }
        // position="bottom"
        width="max-content"
      >
        <HelpIcon fill="none" width="1.65rem" height="1.65rem" />
      </Tooltip>
    </StyledButton>
  );
};

export default OpenHelpPageButton;