import { handleActions } from 'redux-actions';
import { receiveNotification, dismissNotification, dismissAllNotifications } from './actions';

export const defaultState = {
  notifications: {},
};

export default handleActions(
  {
    [receiveNotification]: (state, action) => {
      const actionType = action.payload.type;
      return {
        ...state,
        notifications: { ...state.notifications, [actionType]: action.payload },
      };
    },
    [dismissNotification]: (state, action) => {
      const {
        // I'm guessing this is supposed to remove [action.payload] from state.notifications
        // Maybe some other way to do it?
        // eslint-disable-next-line no-unused-vars
        [action.payload]: value,
        ...tempNotifications
      } = state.notifications;
      return {
        ...state,
        notifications: tempNotifications,
      };
    },
    [dismissAllNotifications]: (state) => ({
      ...state,
      notifications: {},
    }),
  },
  defaultState
);
