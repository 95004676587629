import { combineReducers } from 'redux';

import apiReducer from './api/reducer';
import equipmentReducer from './equipment/equipmentReducer';
import uiReducer from './ui/uiReducer';
import verifyCauseReducer from './verifyCause/verifyCauseReducer';
import modeReducer from './mode/reducer';
import sensorsReducer from './sensors/sensorsReducer';
import notificationReducer from './notifications/reducer';
import rankingHistoryReducer from './rankingHistory/rankingHistoryReducer';

export default combineReducers({
  api: apiReducer,
  equipment: equipmentReducer,
  ui: uiReducer,
  verifyCause: verifyCauseReducer,
  modes: modeReducer,
  sensor: sensorsReducer,
  notifications: notificationReducer,
  rankingHistory: rankingHistoryReducer,
});
