import { call, spawn, put } from 'redux-saga/effects';
import { receiveSensors } from './sensorsActions';
import { fetchSensors } from '../api';

export function* fetchSensorsFromApi() {
  const { response, error } = yield call(fetchSensors);

  if (response) yield put(receiveSensors(response));

  if (error) console.log(error);
}

export default function* sensorsSagas() {
  yield spawn(fetchSensorsFromApi);
}
