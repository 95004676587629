import { handleActions } from 'redux-actions';

import {
  setTimestamp,
  setRewindTimeline,
  setObseravationClicked,
  hoverCard,
  toggleEquipmentPanel,
  highlightSelectedCards,
  selectCause,
  selectConsequence,
  selectPreventions,
  deselectAllHighlightedCards,
  toggleHelpPage,
  toggleHelpKey,
} from './uiActions';

const defaultState = {
  uiEquipmentPanelOpen: false,
  uiSelectedObservations: [],
  uiTimestamp: new Date().toISOString(),
  uiHighlightObservations: [],
  uiHighlightBasedOnObservationId: null,
  uiHighlightedSelectedCards: { causeId: null, observationIds: [], consequenceId: null },
  uiSelectedCause: null,
  uiSelectedConsequence: null,
  uiSelectedPreventions: [],
  uiRewindTimestamp: new Date().toISOString(),
  uiRewindTimeline: [],
  uiObseravationClicked: null,
};

export default handleActions(
  {
    [hoverCard]: (state, { payload }) => {
      const { observationIds = [], observationId = null } = payload;
      return {
        ...state,
        uiHighlightObservations: observationIds,
        uiHighlightBasedOnObservationId: observationId,
      };
    },
    [toggleEquipmentPanel]: (state, action) => {
      return {
        ...state,
        uiEquipmentPanelOpen: action.payload,
      };
    },
    [toggleHelpPage]: (state, action) => {
      return {
        ...state,
        uiHelpPageOpen: action.payload,
      };
    },
    [toggleHelpKey]: (state, action) => {
      return {
        ...state,
        uiHelpKeyOpen: action.payload,
      };
    },
    [setTimestamp]: (state, action) => {
      return { ...state, uiTimestamp: new Date(action.payload).toISOString() };
    },
    [setRewindTimeline]: (state, action) => {
      const { payload } = action;
      return { ...state, uiRewindTimeline: payload };
    },
    [setObseravationClicked]: (state, action) => {
      const { payload } = action;
      return { ...state, uiObseravationClicked: payload };
    },
    [highlightSelectedCards]: (state, { payload }) => {
      const { causeId = null, consequenceId = null, observationIds = [] } = payload;
      return {
        ...state,
        uiHighlightedSelectedCards: {
          causeId,
          observationIds,
          consequenceId,
        },
      };
    },
    [selectCause]: (state, action) => {
      return {
        ...state,
        uiSelectedCause: action.payload,
      };
    },
    [selectConsequence]: (state, action) => {
      return {
        ...state,
        uiSelectedConsequence: action.payload,
      };
    },
    [selectPreventions]: (state, action) => {
      return {
        ...state,
        uiSelectedPreventions: action.payload,
      };
    },
    [deselectAllHighlightedCards]: (state) => {
      return {
        ...state,
        uiHighlightedSelectedCards: {
          causeId: null,
          consequenceId: null,
          observationIds: [],
        },
      };
    },
  },
  defaultState
);
