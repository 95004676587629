import styled from '@emotion/styled/macro';
import React from 'react';
import { ToolIcon } from '../../components/svgIcons/cra-tool';
import { IconUncommitted } from '../../components/svgIcons/cra-uncommited';
import { Heading } from '../../components/Text/Heading';
import { ReactComponent as IconCommitted } from '../../svgs/cra-committed.svg';

const StyledHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  padding: 0 var(--spacing-20);
`;

const StyledWrapper = styled.div`
  margin-top: 1.2rem;
  padding: 0 var(--spacing-30);
  --box-padding: 1.2rem;
  border-bottom: 1px solid #454545;
`;

const List = styled.ul`
  padding: 10px 6px;
  list-style: none;
  display: grid;
  grid-gap: 1rem;
  margin: 0;
  font-size: var(--font-size-14);
`;
const ListItem = styled.li`
  padding: calc(var(--box-padding) / 2);
  height: 50px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  &:hover {
    background-color: var(--color-dark-rgba);
  }
`;

const Icon = styled.div`
  color: var(--color-green);
  margin-right: 1rem;
`;

const CounteractionItem = (props) => {
  const { isCommited, label } = props;
  return (
    <ListItem>
      <Icon>
        {isCommited ? (
          <IconCommitted
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0"
            width="1rem"
            height="1rem"
          />
        ) : (
          <IconUncommitted fill="currentColor" stroke="#fff" />
        )}
      </Icon>
      {label}
    </ListItem>
  );
};

export const Counteraction = (props) => {
  const { counteractions } = props;
  return (
    <StyledWrapper>
      <StyledHead>
        <ToolIcon />
        <Heading>Correct</Heading>
      </StyledHead>
      <List>
        {counteractions.map((ca, index) => {
          return <CounteractionItem key={index} isCommited={false} label={ca} />;
        })}
      </List>
    </StyledWrapper>
  );
};
