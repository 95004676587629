import { createSelector } from 'reselect';
import { equipmentConfig } from '../api/selectors/equipment';

const equipmentSelector = (state) => state.equipment.events;
const notificationsSelector = (state) => state.equipment.notifications;

export const equipmentEvents = (state) => state.api.equipment.events;

export const getNotifications = createSelector(
  equipmentConfig,
  notificationsSelector,
  (config, notifications) => {
    return notifications.map((n) => {
      const [eq] = config[n.equipmentId];
      return {
        ...eq,
        status: n.status,
        occurredOn: n.occurredOn,
      };
    });
  }
);

export const getEquipmentStatuses = createSelector(equipmentSelector, ({ equipmentStatuses }) => {
  return equipmentStatuses;
});
