import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';

import { Line } from '@visx/shape';
import './Card.css';
import { Icon } from '../common/Icon';
import Tooltip from '../common/Tooltip';
import CountCircle from '../common/CountCircle';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import { VerifiedCheckmark } from '../svgIcons/cra-verified-checkmark';

const cardLayout = {
  observation: { columns: 'auto', areas: 'card' },
  consequence: { columns: 'max-content auto', areas: 'count card' },
  cause: { columns: 'auto max-content', areas: 'card count', iconColor: 'var(--color-green)' },
};

const StateSvgName = {
  target: 'cra-target',
  hazard: 'cra-hazard',
  breach: 'cra-breach',
  high: 'cra-high',
  low: 'cra-low',
  normal: 'cra-neutral',
  sensor: 'cra-sensor',
};

const StyledCardWrapper = styled.div`
  font-size: 0.78rem;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: ${({ type }) => cardLayout[type].columns};
  grid-template-areas: '${({ type }) => cardLayout[type].areas}';
  --card-icon-color: ${({ type }) => cardLayout[type].iconColor};
`;

const ContradictionCard = forwardRef(
  (
    {
      data,
      label,
      isSelected,
      isExpanded,
      onCardClick,
      disabled,
      hovered,
      onMouseEnter,
      onMouseLeave,
      id,
      type,
      parentWidth,
      parentHeight,
      isActive,
      count,
      isVerified,
    },
    ref
  ) => {
    const { description, state, isSensor, source } = data;
    const [curElTop, setCurElTop] = useState(0);
    const [curElLeft, setCurElLeft] = useState(0);
    const el = useRef(null);

    // useEffect(() => {
    //   setCurElTop(el.current.offsetTop);
    //   setCurElLeft(el.current.offsetLeft);
    // }, [selected]);

    return (
      <div ref={el}>
        <ParentSize>
          {({ width, height }) => {
            const centerOfParent = parentHeight / 2;
            const centerofEl = curElTop + height / 2;
            return (
              <>
                <button
                  className={'observations-button-wrapper'}
                  type="button"
                  onClick={() => onCardClick(id)}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  disabled={disabled}
                  data-id={id}
                  ref={ref}
                >
                  <StyledCardWrapper type={type}>
                    <div
                      className={`card 'card-unselected' ${isExpanded ? 'card-expanded' : ''} ${
                        hovered ? 'card-hovered' : ''
                      }
                      ${!isActive ? 'is-not-active' : ''}
                      `}
                    >
                      <div className={disabled ? 'card-state-grey' : 'card-state'}>
                        {isVerified ? (
                          <VerifiedCheckmark />
                        ) : (
                          <Icon
                            name={StateSvgName[state]}
                            className="card-state__icon"
                            height="40"
                            width="40"
                            fill="currentColor"
                          />
                        )}
                      </div>
                      <Tooltip message={description} label={label}>
                        <div className="card-body text-ellipsis">
                          <span className="text-bold text-ellipsis">{label}</span>
                          <div className="text-small color-gray">
                            <div className="text-ellipsis">{description}</div>
                          </div>
                        </div>
                      </Tooltip>
                      <div className="sensor-icon">
                        {isSensor && (
                          <Tooltip message={'SENSOR READING'}>
                            <Icon
                              name={StateSvgName.sensor}
                              className="card__sensor-icon"
                              height="25"
                              width="25"
                              fill="currentColor"
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    {count && <CountCircle count={count} />}
                  </StyledCardWrapper>
                </button>
              </>
            );
          }}
        </ParentSize>
      </div>
    );
  }
);

ContradictionCard.displayName = 'Contradiction Card';
export default motion(ContradictionCard);
