import React, { useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';

import { hoverCard, deselectAllHighlightedCards, cardClick } from '../store/ui/uiActions';

import AnimateList from '../components/common/AnimateList';
import useVisibleCardsCount from '../hooks/useVisibleCardsCount';
import { MotionListContainer } from '../components/List/List';
import styled from '@emotion/styled/macro';
import ListButtons from '../components/List/ListButtons';
import { postMoreCausesMetrics } from '../store/api';

const AnimatedList = styled(AnimateList)`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  grid-gap: 0.5em;
  padding: 1rem var(--spacing-30) 3rem;
`;

const MainList = ({
  listName,
  deselectAllHighlightedCards,
  // uiHighlightedSelectedCards,
  children,
}) => {
  const list = React.Children.toArray(children);
  const [visiblesCount, increaseVisibles, resetVisibles] = useVisibleCardsCount(list.length);
  const listRef = useRef(null);
  const buttonsVisible = list.length >= visiblesCount;
  const sessionId = useSelector((state) => state.api.sessionId);
  const increaseVisibleCausesNumber = useCallback(() => {
    increaseVisibles();
    if (listName === 'cause') {
      const postAsyncData = async () => {
        await postMoreCausesMetrics(sessionId);
      };
      postAsyncData();
    }
    setTimeout(function () {
      // HACK: wrapped in a timeout because without it this would fire before the list has had a chance to update.
      const { scrollHeight } = listRef.current;
      listRef.current.scroll({ top: scrollHeight, behavior: 'smooth' });
    }, 10);
  }, [list]);

  const handleResetClick = useCallback(() => {
    listRef.current.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    deselectAllHighlightedCards();
    resetVisibles();
  }, []);

  return (
    <>
      <MotionListContainer ref={listRef} layout="position">
        <AnimatedList className={`list-${listName}s`} type={listName}>
          {list.slice(0, visiblesCount)}
          <ListButtons
            text={`Show more ${listName}s`}
            visible={buttonsVisible}
            onShowMoreClick={increaseVisibleCausesNumber}
            onResetClick={handleResetClick}
          />
        </AnimatedList>
      </MotionListContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  uiHighlightedSelectedCards: state.ui.uiHighlightedSelectedCards,
});

const mapDispatchToProps = (dispatch) => ({
  hoverCard: (action) => dispatch(hoverCard(action)),
  cardClicked: (action) => dispatch(cardClick(action)),
  deselectAllHighlightedCards: () => dispatch(deselectAllHighlightedCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainList);
