import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Circle } from '@visx/shape';
import { Group } from '@visx/group';
import { motion } from 'framer-motion';

import { selectEquipments } from '../../store/api/selectors/equipment';
import useTimescale from '../../hooks/useTimescale';

import EnoIcon from '../../svgs/icon-eno.svg';

const Icon = () => (
  <image xlinkHref={EnoIcon} href={EnoIcon} x="2px" y="1px" width="16px" height="16px" />
);

const statuses = {
  not_running: 'var(--color-observations)',
  running: 'var(--color-green)',
};

const getEquipmentDate = (d) => new Date(d.timestamp);
const getStatus = ({ status }) => statuses[status] || 'transparent';

const TimelineEquipment = ({ now }) => {
  const ref = useRef(null);

  const equipments = useSelector(selectEquipments);
  const [dateScale, , height] = useTimescale(now, ref);

  return (
    <div className="timeline__hours" ref={ref} style={{ overflow: 'hidden' }}>
      <svg className="timeline__svg">
        <Group>
          {equipments.map((equipment) => {
            const x = dateScale(getEquipmentDate(equipment));
            return (
              <motion.g
                key={equipment.equipmentId + equipment.timestamp}
                initial={{ x }}
                animate={{ x }}
                transition={{
                  type: 'tween',
                }}
              >
                <line
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="100%"
                  /* initial={{x1: x, x2: x}}
                    animate={{x1: x, x2: x}} */
                  /* from={{ x, y: 0 }}
                    to={{ x, y: height }} */
                  stroke={getStatus(equipment)}
                  strokeWidth="1"
                />
                {height && (
                  <Group top={height - 10}>
                    <Circle cy="0" r={10} fill={getStatus(equipment)} />
                    <Group top={-10} left={-10}>
                      <Icon />
                    </Group>
                  </Group>
                )}
              </motion.g>
            );
          })}
        </Group>
      </svg>
    </div>
  );
};

export default TimelineEquipment;
