import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import {
  getCauses,
  getConsequences,
  getCorrectiveActions,
  getPreventions,
} from '../graph/graphSelectors';
import { getTimeline } from '../timeline/timelineSelectors';
import { TIMELINE_LENGTH } from '../../constants';
import _ from 'lodash';
import { getModel } from '../api/selectors/model';
import { modeRewind } from '../mode/actions';

export const getEquipmentPanelStatus = (state) => state.uiEquipmentPanelOpen;

export const getObservationsToHighlight = (state) => state.ui.uiHighlightObservations;
export const getHoveredObservationId = (state) => state.ui.uiHighlightBasedOnObservationId;
export const getStaleToggle = (state) => state.ui.uiToggleStaleFilter;
export const getSelectedObservations = (state) => state.ui.uiSelectedObservations;
export const getSelectedConsequence = (state) => state.ui.uiSelectedConsequence;
export const getSelectedPreventions = (state) => state.ui.uiSelectedPreventions;
export const getUIMode = (state) => state.modes.mode;

export const getTimestamp = (state) => state.ui.uiTimestamp;
export const getRewindTimestamp = (state) => state.ui.uiRewindTimestamp;
export const getJSTimestamp = (state) => new Date(state.ui.uiTimestamp);
const modelSelector = (state) => state.api.models;

const getSensorsList = (state) => state.sensor.sensors;
const getAnswersList = (state) => state.verifyCause.answers;

export const getSensorsAndAnswers = createSelector(
  [getSensorsList, getAnswersList],
  (sensors, answers) => {
    return {
      sensors,
      answers,
    };
  }
);

export const getSelectedUuid = (state) => state.ui.uiSelectedCause;

export const getSelectedCause = createSelector(
  [getSelectedUuid, getCauses, getModel, getTimeline],
  (selectedUuid, causes, model, timeline) => {
    const selected = causes.find(({ uuid }) => uuid === selectedUuid) || null;

    if (selected === null) {
      return null;
    }

    const fullContradictionFucntions = selected.contradictingFunctions.map((cf) => {
      const observation = timeline.find((t) => t.functionId === cf);
      return {
        functionId: cf,
        label: model[cf].label ? model[cf].label : '',
        description: model[cf].description ? model[cf].description : '',
        state: observation ? observation.state : selected.state,
      };
    });

    const finalCause = {
      ...selected,
      contradictingFunctions: fullContradictionFucntions,
    };

    return finalCause;
  }
);

export const getObservationsFunctionIdsFromSelectedCause = createSelector(
  [getSelectedCause],
  (cause) => {
    return cause ? cause.observationFunctions : [];
  }
);

export const getObservationsFromSelectedCause = createSelector(
  [getSelectedCause, getTimeline],
  (cause, timeline) => {
    const relatedObservations = cause?.observationFunctions ?? [];
    const sortedObs = relatedObservations
      .map((o) => timeline.find((t) => t.functionId === o))
      .filter((o) => Boolean(o));

    return _.sortBy(sortedObs, ['label'], ['desc']);
  }
);

export const getAllManualObservations = createSelector([getTimeline], (timeline) => {
  const manualInstruments = timeline.filter((element) => {
    return element.source == 'verifyCause' && element.status == 'ONGOING';
  });
  return manualInstruments;
});

export const getManualInstrumentsForSelectedCause = createSelector(
  [getSelectedCause, modelSelector],
  (cause, model) => {
    const relatedInstrumentations = cause?.instrumentationFunctions ?? [];
    return relatedInstrumentations.map(({ functionId, state }) => {
      return {
        state,
        functionId,
        label: model[functionId]?.label,
        concept: model[functionId]?.concept,
      };
    });
  }
);

export const getManualVerifiedFromSelectedCause = createSelector(
  [getManualInstrumentsForSelectedCause, getTimeline],
  (instruments, timeline) => {
    const verifiedInstruments = instruments.filter((o) => {
      return timeline.find(
        (t) =>
          t.functionId === o.functionId &&
          t.observations.find((to) => to.lasted >= DateTime.now().toUTC().toISO())
      );
    });
    const mappedInstruments = instruments.map((vi) => {
      const foundTimeLine = timeline.find((t) => t.functionId === vi.functionId);
      return {
        ...vi,
        status: foundTimeLine ? foundTimeLine.status : null,
      };
    });

    return {
      instruments: mappedInstruments,
      verified: verifiedInstruments,
    };
  }
);

export const isNormal = createSelector(getCauses, (causes) => {
  return causes.length === 0;
});

export const getDateTime = createSelector(
  getTimestamp,
  getUIMode,
  getRewindTimestamp,
  (timestamp, mode, rewindTimestamp) => {
    const now = DateTime.fromISO(mode === 'live' ? timestamp : rewindTimestamp);
    const then = now.minus(TIMELINE_LENGTH);
    return { now, then };
  }
);

export const getHighlightsBasedOnHoveredObservationId = createSelector(
  getHoveredObservationId,
  getCauses,
  getConsequences,
  getSelectedObservations,
  (id, causes, consequences, selectedObservations) => {
    if (selectedObservations && selectedObservations.length > 0) {
      const highlightedCauses = causes
        .filter((cause) => cause.observationFunctions.some((f) => f === id))
        .flatMap((cause) => cause.functionId);

      const highlightedConsequences = consequences
        .filter((consequence) => consequence.observationFunctions.some((f) => f === id))
        .flatMap((consequence) => consequence.functionId);

      return {
        highlighedObservations: [id],
        highlightedCauses,
        highlightedConsequences,
        type: 'observation',
      };
    } else {
      return {
        highlighedObservations: [],
        highlightedCauses: [],
        highlightedConsequences: [],
        type: null,
      };
    }
  }
);

export const getHighlightsBasedOnSelectedCause = createSelector(
  getSelectedCause,
  getHoveredObservationId,
  getConsequences,
  getSelectedObservations,
  (cause, hoveredObservationId, consequences, selectedObservations) => {
    if (
      selectedObservations &&
      selectedObservations.length > 0 &&
      cause &&
      cause.observationFunctions
    ) {
      const highlightedObservations = selectedObservations.filter((observation) =>
        cause.observationFunctions.some((f) => f === observation)
      );

      const highlightedConsequences = consequences
        .filter((consequence) =>
          consequence.observationFunctions.some((f) => f === hoveredObservationId)
        )
        .flatMap((consequence) => consequence.uuid);

      const result = {
        highlightedCauses: [cause.uuid],
        highlighedObservations: highlightedObservations,
        highlightedConsequences: highlightedConsequences,
        type: 'cause',
      };

      return result;
    } else {
      return {
        highlightedCauses: [],
        highlighedObservations: [],
        highlightedConsequences: [],
        type: null,
      };
    }
  }
);

// depercated?
export const getHighlightsBasedOnSelectedConsequence = createSelector(
  getSelectedConsequence,
  getHoveredObservationId,
  getCauses,
  getSelectedObservations,
  (consequence, hoveredObservationId, causes, selectedObservations) => {
    if (
      selectedObservations &&
      selectedObservations.length > 0 &&
      consequence &&
      consequence.observationFunctions
    ) {
      const highlightedObservations = selectedObservations.filter((observation) =>
        consequence.observationFunctions.some((f) => f === observation)
      );

      const highlightedCauses = causes
        .filter((cause) => cause.observationFunctions.some((f) => f === hoveredObservationId))
        .flatMap((cause) => cause.uuid);

      const result = {
        highlightedCauses: highlightedCauses,
        highlighedObservations: highlightedObservations,
        highlightedConsequences: [consequence.uuid],
        type: 'consequence',
      };

      return result;
    } else {
      return {
        highlightedCauses: [],
        highlighedObservations: [],
        highlightedConsequences: [],
        type: null,
      };
    }
  }
);

export const getCorrectiveActionsForSelectedCause = createSelector(
  [getSelectedCause, getCorrectiveActions],
  (selectedCause, correctiveActions) => {
    if (!selectedCause) {
      return [];
    }
    // if Selected Cause State is hazard, then we want to get the corrective actions for true
    // if Selected Cause State is target, then we want to get the corrective actions for false
    // Otherwise, we want to get the corrective actions for the selected cause state
    let normalizedSelectedCauseState = selectedCause.state;
    if (selectedCause.state === 'hazard') {
      normalizedSelectedCauseState = 'true';
    } else if (selectedCause.state === 'target') {
      normalizedSelectedCauseState = 'false';
    }
    const actions = correctiveActions[selectedCause.functionId]?.[normalizedSelectedCauseState];
    let corrective = null;
    if (actions && Array.isArray(actions)) {
      corrective = actions;
    } else if (actions != null) {
      corrective = [actions];
    }
    return corrective;
  }
);

export const getPreventionsForSelectedConsequence = createSelector(
  [getSelectedConsequence, getPreventions, getModel],
  (selectedConsequence, preventions, model) => {
    if (!selectedConsequence) {
      return [];
    }

    let [functionId, state] = selectedConsequence.split('_');
    const consequencePreventions = preventions[functionId]?.[state];

    if (!consequencePreventions) {
      return [];
    }

    const reformattedPreventions = _.map(consequencePreventions, (prevention) => {
      const actId = prevention.actid;
      const modelItem = model[actId];
      const actuatorTag =
        modelItem?.actuatorTag && modelItem?.actuatorTag !== ''
          ? modelItem?.actuatorTag
          : modelItem?.groups?.tag;
      const description = modelItem?.description;

      const finalPrevention = {
        ...prevention,
        actuatorTag: actuatorTag,
        description: description ? description : '',
      };
      return finalPrevention;
    });

    return reformattedPreventions;
  }
);

export const getPreventionsForSelectedConsequenceAndCause = createSelector(
  [getPreventionsForSelectedConsequence, getSelectedCause],
  (preventions, selectedCause) => {
    const consequencePreventions = preventions;
    if (selectedCause === null) return [];
    const finalPreventions = _.filter(consequencePreventions, (element) => {
      return element.rcid === selectedCause.functionId;
    });
    return finalPreventions;
  }
);

export const getPreventionsForSelectedCause = createSelector(
  [getPreventions, getSelectedCause],
  (preventions, selectedCause) => {
    if (!preventions || !selectedCause) {
      return {};
    }

    const finalPreventions = _.mapValues(preventions, (value) => {
      const nestedArrayKey = _.findKey(value, _.isArray);

      if (nestedArrayKey) {
        const filteredArray = _.filter(
          value[nestedArrayKey],
          (item) => item.rcid === selectedCause.functionId
        );
        return { ...value, [nestedArrayKey]: filteredArray }; // We're spreading the existing properties of the value object
      }
      return value;
    });

    return finalPreventions;
  }
);

// depercated?
export const getRelations = createSelector(
  getHighlightsBasedOnHoveredObservationId,
  getHighlightsBasedOnSelectedConsequence,
  getHighlightsBasedOnSelectedCause,
  getSelectedCause,
  getSelectedConsequence,
  (
    relationsBasedOnObservations,
    relationsBasedOnSelectedConsequence,
    relationsBasedOnSelectedCause,
    cause,
    consequence
  ) => {
    if (cause !== null) {
      return relationsBasedOnSelectedCause;
    } else if (consequence !== null) {
      return relationsBasedOnSelectedConsequence;
    } else {
      return relationsBasedOnObservations;
    }
  }
);
