import _, { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

export const getUniquePreventions = (consArr, id) => {
  const preventionsArr = [];

  consArr.forEach((c) => {
    if (c.causes.includes(id)) {
      c.preventive.forEach((prevEl) => preventionsArr.push(prevEl));
    }
  });

  return _.uniqBy(preventionsArr, 'preventiveId');
};

export const compareTwoObjectsKeys = (obj1, obj2) => {
  const obj1KeysArr = Object.keys(obj1);
  const obj2KeysArr = Object.keys(obj2);

  return isEqual(obj1KeysArr, obj2KeysArr);
};

export const getPreventions = (selectedCause, selectedConsequence, modelFile) => {
  const conseqId = selectedConsequence.functionId;
  const conseqState = selectedConsequence.state;

  const causeState = selectedCause.state;

  const foundFunctionInModel = modelFile[conseqId];
  if (!foundFunctionInModel || !foundFunctionInModel.counteractions) return [];

  const allPreventionsByConseqState = foundFunctionInModel.counteractions.prevent[conseqState]
    ? foundFunctionInModel.counteractions.prevent[conseqState]
    : [];

  const preventionsByCause = allPreventionsByConseqState.filter(
    (p) => p.rcid === selectedCause.functionId && p.rcstate === causeState
  );
  const mappedPreventionsByCause = preventionsByCause.map((p) => {
    return {
      id: uuid(),
      label: modelFile[p.actid].actuatorTag
        ? modelFile[p.actid].actuatorTag
        : modelFile[p.actid].label,
      description: modelFile[p.actid].description ? modelFile[p.actid].description : '',
      conseqId,
      action: p.action ? p.action : '',
    };
  });
  return mappedPreventionsByCause;
};

export const compareCausesAndConsequences = (
  stateCauses,
  responseCauses,
  stateConsequences,
  responseConsequences
) => {
  const isArrayEqual = (x, y) => {
    return _(x).differenceWith(y, _.isEqual).isEmpty();
  };

  if (
    stateCauses.length !== responseCauses.length ||
    stateConsequences.length !== responseConsequences.length
  )
    return false;
  const ifCausesEqual = isArrayEqual(stateCauses, responseCauses);
  const ifConsequencesEqual = isArrayEqual(stateConsequences, responseConsequences);

  if (ifCausesEqual && ifConsequencesEqual) return true;
};

export const getBrowserName = () => {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  const isFirefox = /Firefox/.test(navigator.userAgent);
  const isEdge = /Edg/.test(navigator.userAgent);

  if (isEdge) return 'Edge';
  if (isChrome) {
    return 'Chrome';
  } else if (isSafari) {
    return 'Safari';
  } else if (isFirefox) {
    return 'Firefox';
  } else {
    return 'Unknown';
  }
};
