import React from 'react';

export const VerifiedCheckmark = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" stroke="var(--color-observations)" strokeWidth={2} />
      <path
        d="M7.5 11.25L10.5 15L15.75 9"
        stroke="var(--color-observations)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};
