import React from 'react';

export const ToolIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8291 6.82153C15.988 5.66269 16.2837 3.97238 15.7242 2.53781L13.1348 5.12722L11.4405 4.56379L10.8771 2.86948L13.4665 0.280072C12.0319 -0.279368 10.3416 0.0163359 9.18277 1.17518C8.02393 2.33402 7.72823 4.02433 8.28767 5.45889L2.52943 11.2091C1.87408 11.1732 1.20275 11.4049 0.703252 11.9044C-0.231813 12.8395 -0.231813 14.358 0.703252 15.2971C1.63832 16.2361 3.1568 16.2321 4.09586 15.2971C4.59536 14.7976 4.82713 14.1262 4.79116 13.4709L10.5454 7.71264C11.98 8.27608 13.6703 7.98037 14.8291 6.82153ZM1.83412 14.1662C1.52243 13.8545 1.52243 13.347 1.83412 13.0353C2.14581 12.7236 2.6533 12.7236 2.96499 13.0353C3.27668 13.347 3.27668 13.8545 2.96499 14.1662C2.64931 14.4779 2.14581 14.4779 1.83412 14.1662ZM9.46249 7.10125L4.37558 12.1882L3.81214 11.6247L8.89906 6.53781L9.46249 7.10125Z"
        fill="#50E3C2"
      />
    </svg>
  );
};
