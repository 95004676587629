// labels
// stale limit
// urlconst

export const GRAPH_STATE_LABELS = {
  true: 'hazard',
  false: 'target',
  breach: 'breach',
  high: 'high',
  low: 'low',
  normal: 'normal',
  undefined: '',
};

export const STALE_LIMIT = {
  hours: 48,
  minutes: 0,
};

// Do note that this HAS to be minutes.
export const TIMELINE_LENGTH = { minutes: 30 };

export const COLORS = {
  ORANGE: 'var(--color-observations)',
  GRAY: '#979797',
  LIMIT: 'rgba(255, 255, 255, 0.2)',
};
