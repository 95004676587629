import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime, Interval } from 'luxon';
import _ from 'lodash';

import { Line } from '@visx/shape';
import {
  getObservationsFromSelectedCause,
  getSelectedCause,
  getSelectedConsequence,
} from '../store/ui/uiSelectors';

import Circle from '../containers/Circle';
import { isNormal } from '../store/ui/uiSelectors';
import ListHead from '../components/List/ListHead';
import AnimateList from '../components/common/AnimateList';
import { modeIsRewind } from '../store/mode/selectors';

import styled from '@emotion/styled/macro';
import ObservationCard from '../components/cards/ObservationCard';
import { ParentSize } from '@visx/responsive';
import { getConsequences } from '../store/graph/graphSelectors';
import ContradictionCard from '../components/cards/ContradictionCard';
import { setObseravationClicked } from '../store/ui/uiActions';
import HelpScreenLabel from '../components/common/HelpScreenLabel';
import { toggleHelpKey } from '../store/ui/uiActions';

const ObservationsContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;

  //CHECK HERE IF WE HAVE WEIRD OBS STYLING
  overflow: hidden;
  /* overflow-x: visible; */

  ${({ overflowVisible }) =>
    overflowVisible &&
    `
    overflow: visible;
  `}
`;

const ObservationsButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--color-observations);
  color: #2d2f3c;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const ConsequencesCounter = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--color-end-cons);
  color: #2d2f3c;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const EvidenceButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #a3a5b1;
  color: #2d2f3c;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding-top: 93px;
`;

const ObservationsWrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  max-height: 330px;
  border-radius: 20px;
  margin-bottom: 20px;
`;
const EvidenceWrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  max-height: 282px;
  border-radius: 20px;
`;

const WrapperHead = styled.div`
  height: 40px;
  flex-shrink: 0;
  background-color: var(--color-dark);
  border-radius: inherit;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  padding: 0 25px;
  color: #a3a5b1;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  align-items: center;
  background-color: var(--color-dark-shade);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 20px 0;
  gap: 20px;
`;

const AnimateObservationList = styled(AnimateList)`
  display: grid;
  align-content: center;
  grid-gap: 0.7rem;
  justify-content: center;
`;

const ListCircle = styled(Circle)`
  grid-area: centered;
`;

const ObsListHead = styled(ListHead)`
  display: inline-grid;
  justify-items: center;
`;

const SelectedObservationsArea = () => {
  const selected = useSelector(getSelectedCause);
  const dispatch = useDispatch();
  const isCurrentlyNormal = useSelector(isNormal);
  const selectedConsequence = useSelector(getSelectedConsequence);
  const selectedObservations = useSelector(getObservationsFromSelectedCause);
  const consequences = useSelector(getConsequences);
  const uiTimestamp = useSelector((state) => state.ui.uiTimestamp);
  const isRewind = useSelector(modeIsRewind);
  const helpPageOpen = useSelector((state) => state.ui.uiHelpPageOpen);
  const helpKeyOpen = useSelector((state) => state.ui.uiHelpKeyOpen);
  const uiObservationClicked = useSelector((state) => state.ui.uiObseravationClicked);

  // const highlight = useSelector(getObservationsToHighlight);

  const mappedSelectedObservations = useCallback(
    selectedObservations.map((so) => {
      const observations = so.observations;
      const observationWithinTimeRange = observations.find((o) => {
        const interval = Interval.fromDateTimes(
          DateTime.fromISO(o.started),
          DateTime.fromISO(o.lasted)
        );
        return interval.contains(DateTime.fromISO(uiTimestamp)) && so.functionId === o.id;
      });
      const observationState =
        isRewind && observationWithinTimeRange ? observationWithinTimeRange.fault : so.state;

      const foundCons = consequences.find((c) => c.uuid === selectedConsequence);
      if (foundCons) {
        const isActive = foundCons.observationFunctions.some((of) => of === so.functionId);
        return {
          ...so,
          state: observationState,
          isActive,
        };
      }
      return {
        ...so,
        state: observationState,
        isActive: false,
      };
    }),
    [selectedConsequence, selected, uiTimestamp]
  );

  const sortedByActiveMappedSelectedObservations = _.sortBy(
    mappedSelectedObservations,
    (obj) => !obj.isActive
  );

  const handleCardClick = (id) => {
    if (uiObservationClicked === id) {
      dispatch(setObseravationClicked(null));
    } else {
      dispatch(setObseravationClicked(id));
    }
  };
  const toggleHelpKeyPanel = () => {
    dispatch(toggleHelpKey(!helpKeyOpen));
  };

  return (
    <ObservationsContainer overflowVisible={selectedObservations.length > 0 ? false : true}>
      <ObsListHead justify="center" obsHeading={true}></ObsListHead>
      <ParentSize parentSizeStyles={{ width: '100%', height: 'calc(100% - var(--top-bar)' }}>
        {({ width, height }) => {
          return (
            <Wrapper>
              <AnimateObservationList isVisible={true} type="selectedObservations">
                <>
                  {mappedSelectedObservations.length > 0 && (
                    <svg
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: width,
                        height: height,
                      }}
                    >
                      <Line
                        strokeWidth={2}
                        from={{ x: 0, y: 113 }}
                        to={{ x: 20, y: 113 }}
                        stroke="#2D2F3C"
                      />
                      <Line
                        strokeWidth={2}
                        from={{ x: width - 20, y: 113 }}
                        to={{ x: width, y: 113 }}
                        stroke="#2D2F3C"
                      />
                    </svg>
                  )}
                  {!isCurrentlyNormal && (
                    <>
                      <ObservationsWrapper>
                        <WrapperHead>
                          <ObservationsButton>
                            {sortedByActiveMappedSelectedObservations.length}
                          </ObservationsButton>
                          <span className="section-title">Supporting Observations</span>
                          <ConsequencesCounter>
                            {
                              sortedByActiveMappedSelectedObservations.filter((item) => {
                                if (item.isActive === true) {
                                  return item;
                                }
                              }).length
                            }
                          </ConsequencesCounter>
                        </WrapperHead>
                        <Body>
                          {sortedByActiveMappedSelectedObservations.length > 0 &&
                            sortedByActiveMappedSelectedObservations.map((item) => {
                              return (
                                <ObservationCard
                                  type={'observation'}
                                  key={item.functionId + item.fault}
                                  label={item.label}
                                  data={{ ...item, sources: [] }}
                                  id={item.functionId}
                                  parentWidth={width}
                                  parentHeight={height}
                                  selected={selected}
                                  isVerified={item.source === 'verifyCause' ? true : false}
                                  isActive={item.isActive}
                                  onCardClick={handleCardClick}
                                />
                              );
                            })}
                          {sortedByActiveMappedSelectedObservations.length === 0 && (
                            <span>No supporting observations</span>
                          )}
                        </Body>
                      </ObservationsWrapper>
                      {selected && selected.contradictingFunctions.length > 0 && (
                        <EvidenceWrapper>
                          <WrapperHead>
                            <span className="section-title">Conflicting Observations</span>
                            {selected && selected.contradictingFunctions.length > 0 && (
                              <EvidenceButton>
                                {selected.contradictingFunctions.length}
                              </EvidenceButton>
                            )}
                          </WrapperHead>
                          <Body>
                            {!isCurrentlyNormal &&
                              selected &&
                              selected.contradictingFunctions.map((item) => (
                                <ContradictionCard
                                  type={'observation'}
                                  key={item.functionId}
                                  label={item.label}
                                  data={{ ...item, sources: [] }}
                                  isSelected={false}
                                  id={item.functionId}
                                  parentWidth={width}
                                  parentHeight={height}
                                  disabled={false}
                                  isActive={true}
                                  isVerified={item.source === 'verifyCause' ? true : false}
                                  onCardClick={handleCardClick}
                                />
                              ))}
                          </Body>
                        </EvidenceWrapper>
                      )}
                    </>
                  )}
                  {selectedObservations.length > 0 && (
                    <ListCircle
                      isNotEmpty={selectedObservations && selectedObservations.length > 0}
                    />
                  )}
                </>
              </AnimateObservationList>
            </Wrapper>
          );
        }}
      </ParentSize>
    </ObservationsContainer>
  );
};

export default SelectedObservationsArea;
