import { handleActions } from 'redux-actions';
import { DateTime } from 'luxon';

import {
  answerClick,
  answerDialogToggle,
  setVerifyCauseValues,
  resetAllManualObservations,
} from './verifyCauseActions';

const defaultState = {
  answers: {},
  openedDialog: null,
};

export default handleActions(
  {
    [answerClick]: (state, { payload }) => {
      const { functionId, answer, isNormal } = payload;
      if (answer === 'clear') {
        const answersCopy = { ...state.answers };
        delete answersCopy[functionId];
        return {
          ...state,
          answers: answersCopy,
        };
      }
      return {
        ...state,
        answers: {
          ...state.answers,
          [functionId]: isNormal ? 'normal' : answer,
        },
      };
    },
    [resetAllManualObservations]: (state, { payload }) => {
      /* const { functionId, answer, isNormal } = payload;
      return {
        ...state,
        answers: {
          ...state.answers,
          [functionId]: isNormal ? 'normal' : answer,
        },
      };
      */
      // payload.forEach((item) => {});
      // const ongoingObservations = getOngoingObservations();
      console.log(payload);
      return {
        ...state,
      };
    },
    [answerDialogToggle]: (state, { payload }) => {
      const { functionId } = payload;
      return {
        ...state,
        openedDialog: state.openedDialog === functionId ? null : functionId,
      };
    },
    [setVerifyCauseValues]: (state, { payload }) => {
      const res = {};
      const now = DateTime.local();
      payload.forEach((item) => {
        item.observations.forEach((observation) => {
          if (DateTime.fromISO(observation.lasted) > now) {
            res[observation.id] = observation.fault;
          } else {
            delete res[observation.id];
          }
        });
      });
      return {
        ...state,
        answers: res,
      };
    },
  },
  defaultState
);
