import { takeLatest, spawn, fork } from 'redux-saga/effects';
import { setTick } from '../ui/uiActions';
import { fetchTimelineFromApi } from '../api/sagas';

function* updateTimeline() {
  yield fork(fetchTimelineFromApi);
}

function* watchTimelineSagas() {
  yield takeLatest(setTick, updateTimeline);
}

export default function* timelineSagas() {
  // spawn creates a stand-alone task.
  // That is what we want in this case: a task that keep running and listens for an action.
  yield spawn(watchTimelineSagas);
}
