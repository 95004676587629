import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

const TimelineGroupHeading = forwardRef(function TimelineGroupHeading(
  { title, count, position },
  ref
) {
  return (
    <div
      ref={ref}
      className="timeline-observation__counter"
      data-type={title}
      style={{
        gridRow: position,
        gridColumn: 1,
      }}
    >
      <span>{count}</span>
      <span>{title} </span>
    </div>
  );
});

export default motion(TimelineGroupHeading);
